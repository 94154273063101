import { ActionNotSubmitted } from './general/helpers/serverActions';

export const ALLOWED_PAYMENT_METHODS = [
  'eps',
  'scheme',
  'dotpay',
  'giropay',
  'ideal',
  'directEbanking',
  'bcmc',
  'paysafecard',
  'amex',
  'jcb',
  'mc',
  'visa',
  'applepay',
];
export const DEFAULT_GGMAP_REGION = 'AU';
export const DEFAULT_SEPARATOR = '.';
export const DEFAULT_THE_COMMA = ',';

export const GENERIC_ERROR_MESSAGE = 'An unknown error happened, please try again later.';

export const CAR_TRANSFER_FEE_NSW = 39;
export const PLATE_TRANSFER_FEE = 32;

export const MAX_VEHICLE_YEAR = new Date().getFullYear();
export const MIN_VEHICLE_YEAR = 1975;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const PROMO_4 = 'PROMO_4';
export type CAR_PURCHASE_STATUS = 'available' | 'draftOrder' | 'reserved' | 'purchased' | 'delivered';

export const CARD_PAYMENT_AUTHORISED = 'Authorised';

export type AustralianState = 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
export const AUSTRALIAN_STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'] as const;

export const postcodeRegex = /^[0-9]{4}$/;

export const regexName = /^[A-Za-z ,.'-]*$/;

export const regexMobilePhone = /^04[0-9]{2} [0-9]{3} [0-9]{3}$/;

export const regexAbn = /^[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/;

/** Regex parsing all the properties of an Australian address */
/* eslint no-useless-escape: "off" */
export const ADDRESS_REGEX =
  /^\s*(?:(?:(?:(?<c_level_type>level|lvl|floor|flr)\s*(?<c_level_number>\w+),?\s*)?\s*,?\s*(?:(?<c_unit_type>[a-z][\w\-']*)?\s*)?(?:(?<c_unit_number>[\w\.]*(?!\d))\s*[,\/]*\s*)?(?<c_house_number>\d+[a-z]?(?:\s*[-\/,.:;?!]\s*\d+[a-z]?)*))?\s*,?\s*(?<c_street_name>[\w\-\s\'\ ]*)\s+\b(?<c_street_type>fire\s*track|right\s*of\s*way|service\s*way|shopping\s*centre|state\s*highway|\w{2,})\b\s*,?\s*(?<c_suburb>\w+(?:\s+\w+)*)?\s*(?:\n+(?<c_trailing>.+))?)$/gis;

export const STREET_REGEX =
  /^\s*(?<c_house_number>\d+[a-z]?(?:\s*[-,.:;?!]\s*\d+[a-z]?)*)?\s*,?\s*(?<c_street_name>[\w\-\s\'\ ]*)\s+\b(?<c_street_type>fire\s*track|right\s*of\s*way|service\s*way|shopping\s*centre|state\s*highway|\w{2,})\b\s*,?\s*(?<c_trailing>.*)$/gis;

/* eslint-disable camelcase */
export type AddressRegexMatch = {
  c_level_type?: string;
  c_level_number?: string;
  c_unit_type?: string;
  c_unit_number?: string;
  c_house_number?: string;
  c_street_name?: string;
  c_street_type?: string;
  c_suburb?: string;
  c_trailing?: string;
};

export type StreetRegexMatch = {
  c_house_number?: string;
  c_street_name?: string;
  c_street_type?: string;
  c_trailing?: string;
};

export const COOKIE_MICROCOPY_DEBUG = 'microcopyDebug';

export type ProductCardVariant = 'plp' | 'watchlist';

/** Cookie to store temporarily UTM params for the user */
export const COOKIE_UTM = 'sessionUtm';
export const COOKIE_USER_ID = 'userIdCookie';
export const COOKIE_USER_TOKEN = 'userTokenCookie';
export const COOKIE_BROWSER_ID = 'browserIdCookie';
/** Expiry time for the auth cookie in milliseconds */
export const COOKIE_EXPIRY_AUTH = 86400000; // 24 hours in ms
/** Expiry time for the tracking cookie in milliseconds */
export const COOKIE_EXPIRY_TRACKING = 31536000000; // 365 days in ms
/** Cookie for recently viewed cars */
export const COOKIE_RECENTLY_VIEWED = 'recentlyViewedCars';
export const COOKIE_EXPIRY_RECENTLY_VIEWED = 2592000000; // 30 days in ms
/** Cookie to store the trade-in enquiry data */
export const COOKIE_TRADE_IN_ENQUIRY = 'tradeInEnquiry';
/** Cookie to store the trade-in glass vehicle data */
export const COOKIE_TRADE_IN_VEHICLE_DATA = 'tradeInVehicleData';
export const COOKIE_EXPIRY_TRADE_IN_ENQUIRY = 86400000; // 24 hours in ms
/** max number of SKU to cap the recently viewed cars results to */
export const RECENTLY_VIEWED_MAX_SKUS = 13;

export const MAX_TRADE_IN_IMAGE_SIZE_IN_BYTES = 20000000;

/** Length of the phone verification pin */
export const PHONE_PIN_LENGTH = 4;

export const PRESTIGE_MAKES = [
  'AUDI',
  'BMW',
  'ABARTH',
  'ASTON-MARTIN',
  'BENTLEY',
  'FERRARI',
  'JAGUAR',
  'LAMBORGHINI',
  'LAND-ROVER',
  'LOTUS',
  'MASERATI',
  'MCLAREN',
  'MERCEDES',
  'MERCEDES BENZ',
  'MERCEDES-AMG',
  'MERCEDES-MAYBACH',
  'PORSCHE',
  'MINI',
  'ROLLS-ROYCE',
];

export const enum ContentfulEntryId {
  AWARDS_SECTION = '1oY12rIvSNwSmoDtshOQBH',
  CMS_300_POINTS = '5rxTaPRpre7gnvuObZrKaB',
  CUSTOMER_REVIEWS_SECTION_ID = '4Y0ZaQHUoQZiPn5IEDJQsw',
  FINANCE_CALCULATOR_SECTION_ID = '3Mut2215Rv5cENFMm4xOEG',
  FOOTER = '3IeBqp88VBkDOLUXLGsjEV',
  HEADER_NAV_SECTION_ID = '1xX3QuYEMRSwCJHsUnDaVu',
  GLOBAL_SETTINGS = '1LpI4lWFAoaypSHPXGO0SD',
}

export const EXTERIOR_CONDITION_IMAGES_LIMIT = Number(process.env.EXTERIOR_CONDITION_IMAGES_LIMIT ?? 14);
export const INTERIOR_CONDITION_IMAGES_LIMIT = Number(process.env.INTERIOR_CONDITION_IMAGES_LIMIT ?? 10);
export const MAKE_MODEL_CAR_IMAGES_LIMIT = Number(process.env.MAKE_MODEL_CAR_IMAGES_LIMIT ?? 4);
export const OPTIONAL_EXTRA_IMAGES_LIMIT = Number(process.env.OPTIONAL_EXTRA_IMAGES_LIMIT ?? 10);

/** Form field name to pass recaptcha token */
export const RECAPTCHA_FIELD_NAME = 'recaptchaToken';

/**
 * Query param name to indicate we are post SSO authentication
 * Used by middleware to skip UTM cookie serialisation
 */
export const POST_SSO_PARAM = 'postSso';

export const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'] as const;

export const SERVER_ACTION_NOT_SUBMITTED: ActionNotSubmitted = { status: 'initial' };

export const INSTAGRAM_REGEX = RegExp(/^https:\/\/www\.instagram\.com\/(p|reel)\/([A-Za-z0-9_-]+)\/?/);

export const TRADE_IN_PAGE_PATH = '/sell-or-trade-in-my-car';
export const TRADE_IN_FORM_PATH = '/trade-in-enquiry';
