import accountPaymentBankTransferPopupContent from './markdown/ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT.md';
import accountPaymentOptionBankTransferImportantAcceptInfo from './markdown/ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO.md';
import accountPaymentOptionBankTransferImportantInfo from './markdown/ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO.md';
import accountPayIdFormSecondOption from './markdown/ACCOUNT_PAY_ID_FORM_DEBIT_CREDIT_CARD.md';
import accountPayIdFormThirdOption from './markdown/ACCOUNT_PAY_ID_FORM_ONLINE_BANK_TRANSFER.md';
import accountPaymentOptionsFormBankDetailsInfo from './markdown/ACCOUNT_PAY_ID_FORM_PAYMENT_BANK_DETAILS_INFO.md';
import accountPaymentOptionsFormInfo from './markdown/ACCOUNT_PAY_ID_FORM_PAYMENT_DETAILS_INFO.md';
import accountPayIdFormFirstOption from './markdown/ACCOUNT_PAY_ID_FORM_PAY_ID_OPTION.md';
import accountPurchasesCustomerService from './markdown/ACCOUNT_PURCHASES_CUSTOMER_SERVICE.md';
import accountPurchasesCustomerServiceGuarantee from './markdown/ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE.md';
import accountPurchasesMyDocuments from './markdown/ACCOUNT_PURCHASES_MY_DOCUMENTS.md';
import accountPurchasesMyPaymentProgress from './markdown/ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS.md';
import accountPurchasesPayingYourRemainingFunds from './markdown/ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS.md';
import myAccountPaymentReceived from './markdown/ACCOUNT_PURCHASES_PAYMENT_RECEIVED.md';
import myAccountPaymentRemainingAmount from './markdown/ACCOUNT_PURCHASES_PAYMENT_REMAINING_AMOUNT.md';
import myAccountPaymentReservationDeposit from './markdown/ACCOUNT_PURCHASES_PAYMENT_RESERVATION_DEPOSIT.md';
import accountPurchasesRemainingAmountPayment from './markdown/ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT.md';
import authenticationResendPasswordFormCaption from './markdown/AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION.md';
import authenticationSignupPrivacyAndTerm from './markdown/AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS.md';
import authenticationSignupSuccessCarAlerts from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS.md';
import authenticationSignupSuccessCheckout from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT.md';
import authenticationSignupSuccessContent from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_CONTENT.md';
import authenticationSignupSuccessNavbar from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR.md';
import authenticationSignupSuccessPersonalisedQuote from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE.md';
import authenticationSignupSuccessWatchList from './markdown/AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST.md';
import carMatchStartPage from './markdown/CARMATCH_START_PAGE.md';
import checkoutConfirmationCarmaDetails from './markdown/CHECKOUT_CONFIRMATION_CARMA_DETAIL.md';
import checkoutConfirmationDeliveryDocumentList from './markdown/CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST.md';
import checkoutConfirmationNextActionCarmaFinanceCollection from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION.md';
import checkoutConfirmationNextActionCarmaFinanceDelivery from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY.md';
import checkoutConfirmationNextActionCarmaFinanceTradeInCollection from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION.md';
import checkoutConfirmationNextActionCarmaFinanceTradeInDelivery from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY.md';
import checkoutConfirmationNextActionOwnFundsCollection from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION.md';
import checkoutConfirmationNextActionOwnFundsDelivery from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY.md';
import checkoutConfirmationNextActionOwnFundsTradeInCollection from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION.md';
import checkoutConfirmationNextActionOwnFundsTradeInDelivery from './markdown/CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY.md';
import checkoutConfirmationTradeInCard from './markdown/CHECKOUT_CONFIRMATION_TRADE_IN_CARD.md';
import checkoutFinanceHasTradeInCarma from './markdown/CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA.md';
import checkoutFinanceHasTradeInPayWithFunds from './markdown/CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS.md';
import checkoutFinanceLoanCalculator from './markdown/CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE.md';
import checkoutStep7FinanceQuotesAutomotiveUnavailable from './markdown/CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE.md';
import checkoutStep7FinanceQuotesPlentiBusinessUnavailable from './markdown/CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE.md';
import checkoutStep7FinanceQuotesPlentiPersonalUnavailable from './markdown/CHECKOUT_FINANCE_QUOTES_PLENTI_CONSUMER_UNAVAILABLE.md';
import checkoutStep7FinanceQuotesWisrBusinessUnavailable from './markdown/CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE.md';
import checkoutStep7FinanceQuotesWisrPersonalUnavailable from './markdown/CHECKOUT_FINANCE_QUOTES_WISR_CONSUMER_UNAVAILABLE.md';
import checkoutFinanceQuoteFormTermConditions from './markdown/CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS.md';
import checkoutFinanceQuoteInfoFormImportantInformation from './markdown/CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION.md';
import checkoutFinanceQuoteInfoFormTermConditions from './markdown/CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS.md';
import checkoutFinanceQuoteInfoFormTermConditionsForBusiness from './markdown/CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS.md';
import checkoutFinanceQuoteProviderCarma from './markdown/CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA.md';
import checkoutGetStartedViewTermsModalContents from './markdown/CHECKOUT_GET_STARTED_TERMS_MODAL_CONTENT.md';
import magicTermAndPrivacy from './markdown/CHECKOUT_MAGIC_TERM_AND_PRIVACY.md';
import checkoutStepPaymentSummaryFinanceQuotesNote from './markdown/CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE.md';
import checkoutStepPaymentSummaryPaymentMethodPayFullAmount from './markdown/CHECKOUT_PAYMENT_SUMMARY_PAYMENT_METHOD_FULL_AMOUNT.md';
import checkoutStepPaymentSummaryPaymentMethodReservation from './markdown/CHECKOUT_PAYMENT_SUMMARY_PAYMENT_METHOD_RESERVATION.md';
import checkoutStep3CarmaCollect from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_CARMA_COLLECT.md';
import checkoutStep3DeliveryOptionsCollection from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_COLLECTION.md';
import checkoutStep3CollectionAdditionalInformation from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION.md';
import checkoutStep3DeliveryOptionDeliverable from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_DELIVERABLE.md';
import checkoutStep3DeliveryOptionsDelivery from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_DELIVERY.md';
import checkoutStep3DeliveryAdditionalInformation from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION.md';
import checkoutStep3DeliveryOptionHelp from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_HELP.md';
import checkoutStep3DeliveryOptionNotDeliverable from './markdown/CHECKOUT_STEP3_DELIVERY_OPTION_NOT_DELIVERABLE.md';
import checkoutStep3PaymentOptionsChooseFinancier from './markdown/CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER.md';
import checkoutStep3PaymentOptionsSkipFinancier from './markdown/CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER.md';
import checkoutStep5FirstPaymentOption from './markdown/CHECKOUT_STEP5_PAYMENT_OPTION_1.md';
import checkoutStep5SecondPaymentOption from './markdown/CHECKOUT_STEP5_PAYMENT_OPTION_2.md';
import checkoutStep5ThirdPaymentOption from './markdown/CHECKOUT_STEP5_PAYMENT_OPTION_3.md';
import checkoutStep7FinanceQuotesFeeLabel from './markdown/CHECKOUT_STEP7_FINANCE_QUOTES_FEE_LABEL.md';
import checkoutStep4TradeInFirstOption from './markdown/CHECKOUT_STEP_4_TRADE_IN_FIRST_OPTION.md';
import checkoutStep4TradeInSecondOption from './markdown/CHECKOUT_STEP_4_TRADE_IN_SECOND_OPTION.md';
import checkoutStep7FinanceQuotesNote from './markdown/CHECKOUT_STEP_7_FINANCE_QUOTES_NOTE.md';
import checkoutStepBuyTypeFormOptionBusiness from './markdown/CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS.md';
import checkoutStepBuyTypeFormOptionPersonal from './markdown/CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL.md';
import checkoutTradeInHelp from './markdown/CHECKOUT_TRADE_IN_HELP.md';
import generalFooterFormLegals from './markdown/GENERAL_FOOTER_FORM_LEGALS.md';
import generalModalHelpContent from './markdown/GENERAL_MODAL_HELP_CONTENT.md';
import generalPrivacyCollectionNotice from './markdown/GENERAL_PRIVACY_COLLECTION_NOTICE.md';
import hygieneCarFinance from './markdown/HYGIENE_CAR_FINANCE.md';
import hygieneCarFinanceApplication from './markdown/HYGIENE_CAR_FINANCE_APPLICATION.md';
import hygieneCarFinanceQuote from './markdown/HYGIENE_CAR_FINANCE_QUOTE.md';
import hygieneCarFinanceRates from './markdown/HYGIENE_CAR_FINANCE_RATES.md';
import hygieneContactUsGetInTouchContent from './markdown/HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT.md';
import hygieneContactUsGetInTouchHours from './markdown/HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS.md';
import hygieneCovidMessage from './markdown/HYGIENE_COVID_MESSAGE.md';
import hygieneFiveGreatThingAboutFinanceItem1 from './markdown/HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1.md';
import hygieneFiveGreatThingAboutFinanceItem2 from './markdown/HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2.md';
import hygieneFiveGreatThingAboutFinanceItem3 from './markdown/HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3.md';
import hygieneFiveGreatThingAboutFinanceItem4 from './markdown/HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4.md';
import hygieneFiveGreatThingAboutFinanceItem5 from './markdown/HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5.md';
import hygieneFlexiblePaymentColumn1 from './markdown/HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1.md';
import hygieneFlexiblePaymentColumn2 from './markdown/HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2.md';
import hygieneFlexiblePaymentColumn3 from './markdown/HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3.md';
import hygieneFlexiblePaymentReminder from './markdown/HYGIENE_FLEXIBLE_PAYMENT_REMINDER.md';
import hygieneHowFinanceWorksHero from './markdown/HYGIENE_HOW_FINANCE_WORKS_HERO.md';
import hygieneNrma from './markdown/HYGIENE_NRMA.md';
import hygieneOurCommitment1 from './markdown/HYGIENE_OUR_COMMITMENT_COLUMN_1.md';
import hygieneOurCommitment2 from './markdown/HYGIENE_OUR_COMMITMENT_COLUMN_2.md';
import hygieneOurCommitment3 from './markdown/HYGIENE_OUR_COMMITMENT_COLUMN_3.md';
import hygieneOurCommitment4 from './markdown/HYGIENE_OUR_COMMITMENT_COLUMN_4.md';
import hygienePromotion from './markdown/HYGIENE_PROMOTION.md';
import hygieneTradeInEnquirySuccessDescriptionHeader from './markdown/HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER.md';
import hygieneTradeInEnquirySuccessDescriptionMeanwhile from './markdown/HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE.md';
import hygieneTradeInEnquirySuccessDescriptionNext from './markdown/HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT.md';
import hygieneTradeInHowItWorks1 from './markdown/HYGIENE_TRADE_IN_HOW_IT_WORKS_1.md';
import hygieneTradeInHowItWorks2 from './markdown/HYGIENE_TRADE_IN_HOW_IT_WORKS_2.md';
import hygieneTradeInHowItWorks3 from './markdown/HYGIENE_TRADE_IN_HOW_IT_WORKS_3.md';
import pdpCartSwitchExistingPurchase from './markdown/PDP_CART_SWITCH_EXISTING_PURCHASE.md';
import pdpCartSwitchNewPurchase from './markdown/PDP_CART_SWITCH_NEW_PURCHASE.md';
import promo4Banner from './markdown/PDP_EXTENDED_COVERAGE_PROMO_CONTENT.md';
import pdpGalleryModalQuality from './markdown/PDP_GALLERY_MODAL_QUALITY.md';
import pdpGalleryNoImperfections from './markdown/PDP_GALLERY_NO_IMPERFECTIONS.md';
import pdpGalleryNoImperfectionsMobile from './markdown/PDP_GALLERY_NO_IMPERFECTIONS_MOBILE.md';
import pdpGetStartedItem1 from './markdown/PDP_GET_STARTED_ITEM_1.md';
import pdpGetStartedItem2 from './markdown/PDP_GET_STARTED_ITEM_2.md';
import pdpGetStartedItem3 from './markdown/PDP_GET_STARTED_ITEM_3.md';
import personalizedQuoteConfirmationPageContent from './markdown/PERSONALIZED_QUOTE_CONFIRMATION_PAGE_CONTENT.md';
import personalizedQuoteConfirmationPageContentNoQuotes from './markdown/PERSONALIZED_QUOTE_CONFIRMATION_PAGE_CONTENT_NO_QUOTES.md';
import plpNoResultsForm from './markdown/PLP_NO_RESULTS_FORM.md';
import promo1InfoDescription from './markdown/PROMO_1_INFO_DESCRIPTION.md';
import promo1TC from './markdown/PROMO_1_TC.md';
import promo2InfoDescription from './markdown/PROMO_2_INFO_DESCRIPTION.md';
import promo2TC from './markdown/PROMO_2_TC.md';
import promo3InfoDescription from './markdown/PROMO_3_INFO_DESCRIPTION.md';
import promo3TC from './markdown/PROMO_3_TC.md';
import tradeInExteriorFieldSublabel from './markdown/TRADE_IN_EXTERIOR_FIELD_SUBLABEL.md';
import tradeInInteriorFieldSublabel from './markdown/TRADE_IN_INTERIOR_FIELD_SUBLABEL.md';
import tradeInIntroContent from './markdown/TRADE_IN_INTRO_CONTENT.md';
import tradeInThankYouDescriptionMeanwhile from './markdown/TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE.md';
import tradeInThankYouDescriptionNext from './markdown/TRADE_IN_THANK_YOU_DESCRIPTION_NEXT.md';
import tradeInThankYouHeader from './markdown/TRADE_IN_THANK_YOU_HEADER.md';
import { MICROCOPY } from './microcopyDictionary';

const microcopyDefaultDictionary: Readonly<{
  [key in MICROCOPY]: string;
}> = {
  //
  //
  // General microcopy (used across the different pillars of the website)
  //
  //
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_TITLE]: 'Get in touch with us, we’re here to help',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_CONTENT]: 'Mon-Fri: 8AM - 8PM, Sat-Sun: 9AM - 6PM AEST',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL]: 'Email',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE]: 'hello@carma.com.au',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL]: 'Call us',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE]: '02 8319 3210',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL]: 'Text',
  [MICROCOPY.GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE]: '0481 612 333',
  [MICROCOPY.GENERAL_FOOTER_COPYRIGHT]: '© {year} Carma',
  [MICROCOPY.GENERAL_FOOTER_FORM_LEGALS]: generalFooterFormLegals,
  [MICROCOPY.GENERAL_MODAL_HELP_TITLE]: 'Need some help?',
  [MICROCOPY.GENERAL_MODAL_HELP_CONTENT]: generalModalHelpContent,
  [MICROCOPY.GENERAL_COPY_TO_CLIPBOARD_COPIED]: 'Copied to clipboard!',
  [MICROCOPY.GENERAL_CTA_START_CHECKOUT]: 'Get started',
  [MICROCOPY.GENERAL_CTA_NOT_AVAILABLE]: 'View similar cars',
  [MICROCOPY.GENERAL_CTA_MESSAGE]: 'Message us',
  [MICROCOPY.GENERAL_PRIVACY_COLLECTION_NOTICE]: generalPrivacyCollectionNotice,
  [MICROCOPY.GENERAL_EXCLUDE_GOVERNMENT_CHARGES]: 'Excl. govt. charges',
  [MICROCOPY.GENERAL_GOVERNMENT_CHARGES_MODAL_TITLE]: 'Price guide',
  [MICROCOPY.GENERAL_GOVERNMENT_CHARGES_MODAL_NOTE]:
    '* Estimated total price is a guide only based on values provided by government authorities. These fees, duties and charges are subject to change and Carma does not provide any representation on their accuracy.',
  [MICROCOPY.GENERAL_ERROR_500_TITLE]: 'Oops, looks like something went wrong',
  [MICROCOPY.GENERAL_ERROR_500_BUTTON_LABEL]: 'Back to home',
  [MICROCOPY.GENERAL_ERROR_400_TITLE]: 'Whoops, looks like this page is missing',
  [MICROCOPY.GENERAL_ERROR_400_BUTTON_LABEL]: 'Back to home',
  [MICROCOPY.GENERAL_ERROR_PROCESS_PAYMENT_BUTTON_LABEL]: 'Back to Checkout',
  [MICROCOPY.GENERAL_ERROR_PROCESS_PAYMENT_TITLE]: 'Oops...',
  [MICROCOPY.GENERAL_ERROR_PROCESS_PAYMENT_MESSAGE]:
    'An error has occurred while processing your payment. Your card has not been charged. Please check your payment details and try again.',
  [MICROCOPY.GENERAL_PAYMENT_ERROR]: "Payment didn't go through, please try again",
  //
  // Hygiene microcopy
  //
  //
  [MICROCOPY.HYGIENE_HOMEPAGE_HERO_HEADING]: 'Car buying as smooth as the ride. That’s Carma.',
  [MICROCOPY.HYGIENE_HOMEPAGE_HERO_SUBHEADING]:
    'Browse, inspect and buy your next car completely online with free delivery to your door.',
  [MICROCOPY.HYGIENE_HOMEPAGE_HERO_CARMATCH]: `Not sure what you're looking for? Try [CarMatch](/car-match)`,
  [MICROCOPY.HYGIENE_HOMEPAGE_NEW_HERO_HEADING]: "Find the car that's searching for you.",
  [MICROCOPY.HYGIENE_HOMEPAGE_HEADER_NEW_SEARCH_PLACEHOLDER]: 'Search makes, models, or body type',
  [MICROCOPY.HYGIENE_HOMEPAGE_SEARCH_MAKE]: 'Search for make',
  [MICROCOPY.HYGIENE_HOMEPAGE_SEARCH_MODEL]: 'Search for model',
  [MICROCOPY.HYGIENE_HOMEPAGE_BROWSE_ALL_CARS]: 'Browse {total} cars',
  [MICROCOPY.HYGIENE_HOMEPAGE_USP_RETURN]: "7 day Return.<br />We'll collect it from you.",
  [MICROCOPY.HYGIENE_HOMEPAGE_USP_WARRANTY]: '3 month warranty.<br />Relax, your car is covered.',
  [MICROCOPY.HYGIENE_HOMEPAGE_USP_CHECKLIST]: 'Carma Checklist Certified.<br />Examined by experts.',
  [MICROCOPY.HYGIENE_HOMEPAGE_NEW_USP_RETURN]: "7-day money back return.<br />We'll collect it from you.",
  [MICROCOPY.HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST]: '300 point check.<br />Examined by experts.',
  [MICROCOPY.HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER]: 'We believe a great team can achieve anything',
  [MICROCOPY.HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION]:
    'Proud Sponsor and Official Used Car Dealership Partner ',
  [MICROCOPY.HYGIENE_COVID_MESSAGE]: hygieneCovidMessage,
  [MICROCOPY.HYGIENE_WHY_CARMA_CTA]: 'Learn More',
  [MICROCOPY.HYGIENE_NEW_CARS_EVERY_DAY]: 'New cars every day',
  [MICROCOPY.HYGIENE_NRMA]: hygieneNrma,
  [MICROCOPY.HYGIENE_PROMOTION]: hygienePromotion,
  [MICROCOPY.HYGIENE_PROMOTION_LINK]: 'https://offers.carma.com.au/eco-finance-offer?test=true',
  [MICROCOPY.HYGIENE_PROMOTION_CTA]: 'Find out more',
  [MICROCOPY.HYGIENE_CAR_FINANCE]: hygieneCarFinance,
  [MICROCOPY.HYGIENE_CAR_FINANCE_RATES]: hygieneCarFinanceRates,
  [MICROCOPY.HYGIENE_CAR_FINANCE_QUOTE]: hygieneCarFinanceQuote,
  [MICROCOPY.HYGIENE_CAR_FINANCE_APPLICATION]: hygieneCarFinanceApplication,
  [MICROCOPY.HYGIENE_CAR_FINANCE_CTA]: 'Find out more',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_TITLE]: 'We think we have found your car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_SUBTITLE]:
    'Is this the car you want to sell or trade-in? Please confirm to move forward.',
  [MICROCOPY.TRADE_IN_CAR_FOUND_FIELD_DETAILS_CORRECT]: 'Are these details correct?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_REGO]: 'Registration plate',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MAKE_NAME]: 'Make',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_NAME]: 'Model',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_VARIANT]: 'Variant',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_YEAR]: 'Build year',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION]: 'Transmission',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_AUTOMATIC]: 'Automatic',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_MANUAL]: 'Manual',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE]: 'Fuel type',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PETROL]: 'Petrol',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_DIESEL]: 'Diesel',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_HYBRID]: 'Hybrid',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_ELECTRIC]: 'Electric',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PLUG_IN_HYBRID]: 'Plug-in Hybrid',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_LPG]: 'LPG',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE]: 'Body type',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SUV]: 'SUV',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_UTE]: 'Ute',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_HATCH]: 'Hatch',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SEDAN]: 'Sedan',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_VAN]: 'Van',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_COUPE]: 'Coupe',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_WAGON]: 'Wagon',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_PEOPLE_MOVER]: 'People Mover',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_CONVERTIBLE]: 'Convertible',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_TITLE]: 'The basics',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_SUBTITLE]: 'Help us get a better picture of your car.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN]: 'Are you looking to sell or trade-in your car?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD]: '',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_SELL]: 'Sell',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_TRADE_IN]: 'Trade in',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON]: 'Why are you selling your car?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_NO_LONGER_NEED]: 'No longer need my car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_USED]: 'Buying another used car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_NEW]: 'Buying another new car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS]: 'Have you found your next car yet?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_ALREADY_FOUND]:
    "I've already found my next car",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_STILL_LOOKING]:
    "I'm still looking for my next car",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_NOT_STARTED]:
    "I haven't started searching for my next car yet",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE]:
    'When are you ideally looking to sell your car?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_ASAP]:
    "ASAP - I'm ready to sell my car immediately",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION3_6_WEEKS]: 'In the next 3-6 weeks',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_FEW_MONTHS]:
    "In the next few months, I'm in no rush",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_CAR_FOUND]:
    'As soon as I find my next car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NOT_SELLING_YET]:
    'Not looking to sell yet, just interested to know value of my car for now',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING]:
    'Is there any finance owing on your vehicle?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING_SUBTITLE]:
    "It's fine if there is! One of our team members will talk to you about how you wish to resolve any outstanding finance.",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER]: 'Odometer reading',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER_PLACEHOLDER]: 'e.g. 30000',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_COLOUR]: 'Car colour',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_OPTIONAL_EXTRAS_TITLE]: 'Custom options / aftermarket accessories',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS]:
    'Are there any optional extras or after-market accessories provided with the vehicle?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_PLACEHOLDER]:
    'Type your optional extra and hit enter',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_HELPER]:
    'e.g. Apple Carplay, Roof racks',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_TITLE]: 'Mechanical condition',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS]:
    'Does the car dash show any warning lights?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_CHECK_ENGINE]:
    'Check engine light',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_AIRBAG]: 'Airbag or SRS light',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRACTION_ABS]:
    'Traction control or ABS light',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRANSMISSION]:
    'Transmission warning light',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_OTHER]: 'Other',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_NULL]: 'No warning lights',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES]:
    'Does your car have any mechanical issues?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES_DESCRIPTION]:
    'Issues with engine, gearbox, transmission',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_TITLE]: 'Exterior condition',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE]:
    'Please indicate any damage to the exterior of your car.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_SCRATCH_DENT]:
    'Major scuffs, scratches or dents',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_SCRATCH_DENT]:
    'Minor scuffs, scratches or dents',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_HAIL]: 'Major hail damage',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_HAIL]: 'Minor hail damage',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_PAINT]: 'Faded paint',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_WINDSHIELD]:
    'Chipped or cracked windshield',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_RUST]: 'Rust',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_NULL]: 'No exterior damage',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_TITLE]: 'Interior condition',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE]:
    'Please indicate any damage to the interior of your car.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_STAINS]: 'Noticeable stains',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_RIPPED_SEATS]:
    'Rips or tears in seats',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR]: 'Persistent odours',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR_DESCRIPTION]:
    'From pets, smoking, mildew etc.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V]: 'Damaged A/V systems',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V_DESCRIPTION]:
    'Entertainment, navigation, etc.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_DASHBOARD_PANELS]:
    'Damaged dashboard or interior panels',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_NULL]: 'No interior damage',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_TITLE]: 'Car history',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES]: 'Distance driven on current tyres',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_PLACEHOLDER]: 'Select an option',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNDER_1K]: 'Less than 1,000km (like new)',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_1K_TO_5K]: 'Driven less than 5,000 km',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_OVER_5K]: 'Driven more than 5,000 km',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNKNOWN]: "I don't know",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_MAJOR_ACCIDENT]: 'Has your car been in a major accident?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY]:
    'What level of documented service history do you have for the vehicle?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_PLACEHOLDER]: 'Select an option',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_FULL]:
    'You have records of every required service, performed by the manufacturer.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL_GENUINE]:
    'You have records of every required service, performed by the manufacturer or an independent workshop.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL]:
    'You have records of some services.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_NONE]:
    "You don't have records of any service.",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_FIRST_OWNER]: 'Are you the first owner?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS]: 'How many keys do you have for this car?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_1]: '1',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_2_PLUS]: '2+',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_NAME]: 'Car photos',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_TITLE]: 'Next, we need some photos of your car.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_SUBTITLE]:
    'Follow these steps to make sure your car photos clearly represent your car and its condition.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_1]:
    'Hold your phone steady - no one likes a blurry photo!',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_2]:
    'Take your photos outside in a well lit area. We want to see your car in all its glory.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_3]:
    'Make sure the whole car is in view before you take your photo.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_TITLE]: 'Upload your photos',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SUBTITLE]: 'Max file size 20MB\nFile types supported - JPG, PNG, HEIC',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_FRONT]: 'Front of car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_LEFT]: 'Left side of car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_RIGHT]: 'Right side of car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_BACK]: 'Back of car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_TITLE]: 'Easier on mobile?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_DESCRIPTION]:
    'Scan this QR code to continue from your mobile. Easily upload photos right from your phone camera.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_TITLE]: 'Not ready to take photos yet?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_DESCRIPTION]:
    'Pause the process and we’ll send an email to <strong>{email}</strong> allowing you to continue on when it suits you. Easy!',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_BUTTON_LABEL]: 'Email me a link to continue later',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_TITLE]: 'Final details',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_CAR_LOCATION]: 'Where is the car located?',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT]:
    'How much do you think your car is worth? (Optional)',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_SUBTITLE]:
    'Your estimated value will give us a reference point to work with while valuating your car, please provide us with your best estimate.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_PLACEHOLDER]: 'Enter amount',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_TITLE]: 'Get your offer',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_SUBTITLE]:
    'Tell us a little about yourself and we will get back to you with an offer',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_FIRSTNAME]: 'First name',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_LASTNAME]: 'Last name',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_EMAIL]: 'Email',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_PHONE]: 'Phone number',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER]: hygieneTradeInEnquirySuccessDescriptionHeader,
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT]: hygieneTradeInEnquirySuccessDescriptionNext,
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE]: hygieneTradeInEnquirySuccessDescriptionMeanwhile,
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_OPTIONAL_PDP]:
    "Ensure the car you were browsing doesn't slip away. Reserve it now with a fully refundable $500 deposit.",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE]: 'Go to Carma Home',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE_PDP_VARIANT]: 'Reserve',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_UPLOAD_IMAGE]: 'Upload image',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_REMOVE_IMAGE]: 'Remove image',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_DROP_IMAGE]: 'Drop image here',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_INVALID_FILE_TYPE]:
    'Unsupported file type, please choose an image in a supported format.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_LARGE]: 'File too large, please choose a smaller image.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_SMALL]: 'File too small, please choose a larger image.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_TOO_MANY_FILES]: 'Too many files, please choose one image.',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_DEFAULT_NEXT]: 'Next',
  [MICROCOPY.HYGIENE_TRADE_IN_HOW_IT_WORKS_TITLE]: 'How it works',
  [MICROCOPY.HYGIENE_TRADE_IN_HOW_IT_WORKS_1]: hygieneTradeInHowItWorks1,
  [MICROCOPY.HYGIENE_TRADE_IN_HOW_IT_WORKS_2]: hygieneTradeInHowItWorks2,
  [MICROCOPY.HYGIENE_TRADE_IN_HOW_IT_WORKS_3]: hygieneTradeInHowItWorks3,
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_TITLE_1]: 'Trade in or simply sell your car',
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_TITLE_2]: 'Fair and accurate valuations',
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_TITLE_3]: "A process that's smooth and easy",
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_CONTENT_1]:
    "Sell your car to Carma, whether or not you're buying another car from us.",
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_CONTENT_2]: 'We use up-to-date market data to price your car.',
  [MICROCOPY.HYGIENE_TRADE_IN_USPS_CONTENT_3]: 'We’ll even come and collect the car from you.',
  [MICROCOPY.HYGIENE_TRADE_IN_TAG_REQUIRED]: 'Required',
  [MICROCOPY.HYGIENE_TRADE_IN_TAG_OPTIONAL]: 'Optional',
  [MICROCOPY.HYGIENE_TRADE_IN_TAG_COMPLETE]: 'Complete',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_TITLE]: "Your car's exterior condition",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_TITLE]: "Your car's interior condition",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_TITLE]: "Your car's make and model",
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_TITLE]: 'Optional extras',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_SUBTITLE]:
    'Upload photos of exterior conditions you have selected',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_SUBTITLE]:
    'Upload photos of interior conditions you have selected',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_SUBTITLE]:
    'Upload photos to help identify and verify your car',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_SUBTITLE]:
    'Upload photos of your car’s extras to enhance valuation',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_BUTTON]: 'Upload photos',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SAVE_UPLOAD_BUTTON]: 'Save & upload',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_HEADING]: 'Click to upload your image',
  [MICROCOPY.HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_TEXT]: 'JPG, PNG, or HEIF less than 20MB',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_TITLE]: 'Looking for a specific size?',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_0_LABEL]: 'SUV',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_1_LABEL]: 'Ute',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_2_LABEL]: 'Hatch',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_3_LABEL]: 'Sedan',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_4_LABEL]: 'Van',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_5_LABEL]: 'Coupe',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_6_LABEL]: 'Wagon',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_7_LABEL]: 'People mover',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_8_LABEL]: 'Convertible',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_0_CTA]: 'View all SUVs',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_1_CTA]: 'View all Utes',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_2_CTA]: 'View all Hatches',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_3_CTA]: 'View all Sedans',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_4_CTA]: 'View all Vans',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_5_CTA]: 'View all Coupes',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_6_CTA]: 'View all Wagons',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_7_CTA]: 'View all People movers',
  [MICROCOPY.HYGIENE_CAROUSEL_BODY_TYPE_8_CTA]: 'View all Convertibles',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_TITLE]: 'Looking for a perfect match for your budget?',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_0_LABEL]: 'Under $20K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_1_LABEL]: '$20-40K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_2_LABEL]: '$40-60K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_3_LABEL]: '$60-80K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_4_LABEL]: 'Over $80K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_0_CTA]: 'Browse all cars under $20K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_1_CTA]: 'Browse all cars between $20-40K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_2_CTA]: 'Browse all cars between $40-60K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_3_CTA]: 'Browse all cars between $60-80K',
  [MICROCOPY.HYGIENE_CAROUSEL_BUDGET_4_CTA]: 'Browse all cars over $80K',
  [MICROCOPY.HYGIENE_CAROUSEL_PROMOTION_TITLE]: 'Check out our latest promotions!',
  [MICROCOPY.HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT]: hygieneContactUsGetInTouchContent,
  [MICROCOPY.HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS]: hygieneContactUsGetInTouchHours,
  [MICROCOPY.HYGIENE_BENEFIT_HEADER]: 'Remember, every car comes with:',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_1_TITLE]: '7 day money-back promise',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_1_CONTENT]:
    'If it’s not right for you, we’ll come pick it up, refund you and help you find a replacement.',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_2_TITLE]: '3 month warranty',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_2_CONTENT]: 'Relax, your car is protected by a comprehensive 3 month warranty.',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_3_TITLE]: '1 year roadside assistance',
  [MICROCOPY.HYGIENE_BENEFIT_COLUMN_3_CONTENT]:
    'For additional peace of mind, we give you a year’s NRMA roadside assistance membership.',

  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_HEADER]: 'Five great things about finance',
  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1]: hygieneFiveGreatThingAboutFinanceItem1,
  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2]: hygieneFiveGreatThingAboutFinanceItem2,
  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3]: hygieneFiveGreatThingAboutFinanceItem3,
  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4]: hygieneFiveGreatThingAboutFinanceItem4,
  [MICROCOPY.HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5]: hygieneFiveGreatThingAboutFinanceItem5,

  [MICROCOPY.HYGIENE_HOW_FINANCE_WORKS_HERO]: hygieneHowFinanceWorksHero,
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_HEADER]: 'Pay your way with <br /> Carma’s flexible payment options',
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_CTA]: 'Find out more',
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1]: hygieneFlexiblePaymentColumn1,
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2]: hygieneFlexiblePaymentColumn2,
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3]: hygieneFlexiblePaymentColumn3,
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_REMINDER]: hygieneFlexiblePaymentReminder,
  [MICROCOPY.HYGIENE_FLEXIBLE_PAYMENT_ADS]: 'Powered by',

  [MICROCOPY.HYGIENE_FLEXIBLE_TABLE_SECTION_NONE_LABEL]: 'None',

  [MICROCOPY.HYGIENE_FINANCE_PROVIDERS_HEADER]: 'Finance provided by',
  [MICROCOPY.HYGIENE_FINANCE_PROVIDERS_COLUMN_1_DESCRIPTION]:
    'Automotive Finance love cars and finance! Their products and rates are tailored to you and to your circumstances. Their systems and platform can support and sign you up digitally wherever you are, so throw away your pen and they’ll do all the hard work for you!',
  [MICROCOPY.HYGIENE_FINANCE_PROVIDERS_COLUMN_2_DESCRIPTION]: `Plenti is Australia’s leading fintech consumer lender, providing faster and fairer loans to people who want to bring their big ideas to life. Plenti's technology - developed entirely in-house allows them to provide loans with personalised rates and best-in-class customer service.`,
  [MICROCOPY.HYGIENE_FINANCE_PROVIDERS_COLUMN_3_DESCRIPTION]:
    'Get behind the wheel sooner with a personalised loan. Take the smart route with low rates, no ongoing fees and same-day settlement.',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_HEADER]: 'How Carma works',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_CTA]: 'Read more',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_1_TITLE]: 'Find and reserve your <br /> perfect car',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_2_TITLE]: 'Payments, financing, trade in. We’re here to help.',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_3_TITLE]: 'Choose free delivery or collect, then start driving',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_1_SUMMARY]:
    'Whatever make or model find and reserve your perfect car from our full range of high quality preloved cars.',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_2_SUMMARY]:
    'We know everyone’s car buying experience is a little different. Whether you’re financing, buying outright, trading in, we’re here to help. ',
  [MICROCOPY.HYGIENE_HOW_CARMA_WORKS_COLUMN_3_SUMMARY]:
    'Once ready, choose to have your car delivered free to your door or collect from our convenient location in Alexandria, Sydney. ',
  [MICROCOPY.HYGIENE_SOCIAL_PROOF_HEADER]: 'Over 130 5-star reviews and counting',
  [MICROCOPY.HYGIENE_SOCIAL_PROOF_DESCRIPTION_1]:
    'We’re on a mission to create the easiest and best way to buy a used car in Australia and we’d love to have you come along for the ride.',
  [MICROCOPY.HYGIENE_SOCIAL_PROOF_DESCRIPTION_2]:
    'See what Carma customers are saying about their experience on Google and ProductReview.com.au.',

  [MICROCOPY.HYGIENE_OUR_COMMITMENT_HEADER]: 'Our commitment to quality',
  [MICROCOPY.HYGIENE_OUR_COMMITMENT_COLUMN_1]: hygieneOurCommitment1,
  [MICROCOPY.HYGIENE_OUR_COMMITMENT_COLUMN_2]: hygieneOurCommitment2,
  [MICROCOPY.HYGIENE_OUR_COMMITMENT_COLUMN_3]: hygieneOurCommitment3,
  [MICROCOPY.HYGIENE_OUR_COMMITMENT_COLUMN_4]: hygieneOurCommitment4,
  [MICROCOPY.HYGIENE_EMAIL_UNSUBSCRIBE_TITLE]: "You've been unsubscribed from Watchlist price drop notifications.",
  [MICROCOPY.HYGIENE_EMAIL_UNSUBSCRIBE_WATCHLIST_VEHICLE_AVAILABLE_TITLE]:
    "You've been unsubscribed from watchlist availability alerts.",
  [MICROCOPY.HYGIENE_EMAIL_UNSUBSCRIBE_BUTTON_LABEL]: 'Back to home',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_TITLE]: 'How much do you want to spend?',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE]: 'Term of loan (years)',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT]: 'Your estimated purchase price',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT]: 'Your estimated weekly repayment',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_CTA]: 'Chat to our finance team',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1]: 'Weekly payment',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2]: 'Deposit amount',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3]: 'Purchase price',
  [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE]: '/week*',
  [MICROCOPY.HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_TITLE]: "You've been unsubscribed from this search alert",
  [MICROCOPY.HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_CONTENT]:
    'You may still receive emails for other alerts you have configured. These can be controlled in the Notification Settings section of your account',
  [MICROCOPY.HYGIENE_EMAIL_DEFAULT_UNSUBSCRIBE_TITLE]: "You've been unsubscribed",
  [MICROCOPY.HYGIENE_JOIN_THE_1000S]: 'Join the 1000’s of Aussies Carma’s helped get on the road',
  [MICROCOPY.HYGIENE_WHAT_HAPPENS_NEXT_TITLE]: 'What happens next?',
  [MICROCOPY.HYGIENE_WHAT_HAPPENS_NEXT_DESCRIPTION]: `We'll call you within 24 hours to finalise the rest of your purchase. At the same time we’ll do a check over your car and start prepping it for delivery or collection!`,
  //
  //
  // Page Builder/Flexible Content microcopy
  //
  //
  [MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_CTA_LABEL]: 'View all',
  [MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_GOOGLE_COUNT]: '174',
  [MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_GOOGLE_AVERAGE]: '4.8',
  [MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_COUNT]: '174',
  [MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_AVERAGE]: '4.8',
  //
  //
  // Product listing microcopy (PLP)
  //
  //
  [MICROCOPY.PLP_MARKETING_BANNER_TITLE]: 'Not sure what you’re looking for?',
  [MICROCOPY.PLP_MARKETING_BANNER_DESCRIPTION]: 'Let us know what you need, and we’ll match you with the right cars.',
  [MICROCOPY.PLP_MARKETING_BANNER_CTA]: 'Start CarMatch',
  //
  //
  // Product detail microcopy (PDP)
  //
  //
  [MICROCOPY.PROMO_1]: '3.0% p.a. rate discount*',
  [MICROCOPY.PROMO_1_TAB_LABEL]: '3% p.a. interest rate discount on Plug-in Electric / Hybrid',
  [MICROCOPY.PROMO_1_CTA_LABEL]: 'Browse all eligible cars',
  [MICROCOPY.PROMO_1_URL_PARAM]: 'plug-in-elecric',
  [MICROCOPY.PROMO_1_TC]: promo1TC,
  [MICROCOPY.PROMO_1_INFO_DESCRIPTION]: promo1InfoDescription,
  [MICROCOPY.PROMO_1_INFO_LINK]: 'https://offers.carma.com.au/eco-finance-offer?test=true',
  [MICROCOPY.PROMO_1_INFO_CTA]: 'Find out more',
  [MICROCOPY.PROMO_2]: '2.5% p.a. rate discount*',
  [MICROCOPY.PROMO_2_TAB_LABEL]: '2.5% p.a. interest rate discount on Self Charging Electric / Hybrid',
  [MICROCOPY.PROMO_2_CTA_LABEL]: 'Browse all eligible cars',
  [MICROCOPY.PROMO_2_URL_PARAM]: 'self-charging-electric',
  [MICROCOPY.PROMO_2_TC]: promo2TC,
  [MICROCOPY.PROMO_2_INFO_DESCRIPTION]: promo2InfoDescription,
  [MICROCOPY.PROMO_2_INFO_LINK]: 'https://offers.carma.com.au/eco-finance-offer?test=true',
  [MICROCOPY.PROMO_2_INFO_CTA]: 'Find out more',
  [MICROCOPY.PROMO_3]: 'Black Friday',
  [MICROCOPY.PROMO_3_TAB_LABEL]: 'Black Friday Sale',
  [MICROCOPY.PROMO_3_CTA_LABEL]: 'Browse all eligible cars',
  [MICROCOPY.PROMO_3_URL_PARAM]: 'black-friday-sale',
  [MICROCOPY.PROMO_3_TC]: promo3TC,
  [MICROCOPY.PROMO_3_INFO_DESCRIPTION]: promo3InfoDescription,
  [MICROCOPY.PROMO_3_INFO_LINK]: '/used-cars/black-friday-sale',
  [MICROCOPY.PROMO_3_INFO_CTA]: 'See More',
  [MICROCOPY.PROMO_4]: 'Bonus Extended Coverage',
  [MICROCOPY.PROMO_4_BANNER]: promo4Banner,
  [MICROCOPY.PDP_SET_LOCATION_FOR_DELIVERY_ADD_LABEL]: 'Set location',
  [MICROCOPY.PDP_SET_LOCATION_FOR_DELIVERY_EDIT_LABEL]: 'Edit',
  [MICROCOPY.PDP_DELIVERY_LOCATION_POPUP_TITLE]: 'Check if we can deliver to you',
  [MICROCOPY.PDP_DELIVERY_LOCATION_POPUP_DESCRIPTION]:
    "We are early in the journey and at this stage we are only delivering in NSW. We'll have other states in the future!",
  [MICROCOPY.PDP_DELIVERY_LOCATION_POPUP_BUTTON_LABEL]: 'Set location',
  [MICROCOPY.PDP_DELIVERY_LOCATION_POPUP_SEARCH_LABEL]: 'Enter your postcode',
  [MICROCOPY.PDP_DELIVERY_LOCATION_POPUP_CANCEL_LABEL]: 'Cancel',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_TITLE]: 'Someone else has placed a deposit on this car',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_TITLE]: 'There is a purchase in progress',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_CONTENT]:
    'Another customer is in the process of purchasing this car. We have placed it on hold for them while they complete their purchase. If they decide not to go ahead, this car may become available again. <br />  <br /> You can check back to see the status of this car later, or view similar available cars.',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_CONTENT]:
    'This car is currently unavailable. Don’t despair however! Sometimes purchases don’t go ahead, so be sure to check back. In the meantime, you can view similar cars currently in our inventory.',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_MODAL_CONFIRM_BUTTON_LABEL]: 'Okay',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_MODAL_ADD_TO_WATCHLIST]: 'Add to Watchlist',
  [MICROCOPY.PDP_PURCHASE_INPROGRESS_MODAL_ADDED_TO_WATCHLIST_LABEL]: 'Added to watchlist',
  [MICROCOPY.PDP_PRICE_CARD_CONTINUE_CHECKOUT_BUTTON_LABEL]: 'Continue checkout',
  [MICROCOPY.PDP_PRICE_CARD_BUY_OR_RESERVE_CAR_BUTTON_LABEL]: 'Buy or reserve now',
  [MICROCOPY.PDP_PRICE_CARD_ON_HOLD_CAR_NOTE]: 'This car is currently on hold. ',
  [MICROCOPY.PDP_PRICE_CARD_ON_HOLD_CAR_LEARN_MORE]: 'Learn more',
  [MICROCOPY.PDP_PRICE_CARD_CAR_PURCHASED_LABEL]: 'Car purchased',
  [MICROCOPY.PDP_CAR_SUMMARY_NO_ANCAP_RATING_LABEL]: 'Not rated',
  [MICROCOPY.PDP_CAR_SUMMARY_SERVICE_NOT_REQUIRED_LABEL]: 'Not required',
  [MICROCOPY.PDP_CAR_SUMMARY_HEADER]: 'Summary',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_HEADER]: 'Basics',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_1]: 'Body type',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_2]: 'Exterior colour',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_3]: 'Number of keys',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_4]: 'VIN',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_5]: 'Compliance year',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_6]: 'Build year',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_1_TITLE_7]: 'Odometer',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_HEADER]: 'Fuel & Consumption',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_1]: 'Fuel type',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_2]: 'Fuel tank capacity',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_3]: 'Fuel consumption',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_4]: 'Battery capacity',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_5]: 'Electric range',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_TITLE_6]: 'Battery consumption',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_2_FOOTER]:
    '*All electric vehicles specifications are based on information obtained from Glass’s Guide for new vehicles. It is important to note that specifications may vary based on the make, model, age, milage, weather conditions and/or other factors including variations in performance of battery capacity and battery consumption.',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_3_HEADER]: 'Safety',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_3_TITLE_1]: 'ANCAP safety rating',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_HEADER]: 'Performance',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_1]: 'Emissions CO2',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_2]: 'Drivetrain',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_3]: 'Cylinders',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_4]: 'Gearbox',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_5]: 'Engine power',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_4_TITLE_6]: 'Engine size',
  [MICROCOPY.PDP_CAR_SUMMARY_TOW_CAP_BRAKED]: 'Towing Capacity (Braked)',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_5_HEADER]: 'Dimensions',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_5_TITLE_1]: 'Seats',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_5_TITLE_2]: 'Weight',
  [MICROCOPY.PDP_CAR_SUMMARY_SECTION_5_TITLE_3]: 'Length',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_EXTERIOR_HEADER]: 'Basics',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_1]: 'Body type',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_2]: 'Exterior colour',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_3]: 'Drive type',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_MECHANICAL_HEADER]: 'Mechanical',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_1]: 'Cylinders',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_2]: 'Gearbox',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_3]: 'Engine power',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_FUEL_HEADER]: 'Performance and economy',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_1]: 'Fuel consumption',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_2]: 'Emissions CO2',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_3]: 'ANCAP safety rating',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_INTERIOR_HEADER]: 'Interior',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_1]: 'Interior colour',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_2]: 'Material',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_3]: 'Number of keys',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_SIZES_HEADER]: 'Weight and dimensions',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_1]: 'Weight',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_2]: 'Length',
  [MICROCOPY.PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_3]: 'Fuel tank capacity',
  [MICROCOPY.PDP_CAR_FEATURES_HEADER]: 'Features',
  [MICROCOPY.PDP_CAR_FEATURES_ITEM_1_HEADER]: 'Installed options',
  [MICROCOPY.PDP_CAR_FEATURES_ITEM_2_HEADER]: 'Aftermarket accessories',
  [MICROCOPY.PDP_CAR_FEATURES_ITEM_3_HEADER]: 'Standard features',
  [MICROCOPY.PDP_CAR_FEATURES_BUTTON_SHOW_ALL_LABEL]: 'Show all features',
  [MICROCOPY.PDP_PRICE_CARD_FINANCE]: 'OR est. __{price}__ 5 yr based on __{interestRate}__^',
  [MICROCOPY.PDP_PRICE_CARD_FINANCE_SHORT]: 'or est. __{price}__^',
  [MICROCOPY.PDP_PRICE_CARD_FINANCE_FOOTNOTE]: '^Fees and charges apply',
  [MICROCOPY.PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK_MOBILE]:
    'est. __{price}/wk__ 5yr term based on an average __{interestRate}% p.a.*__',
  [MICROCOPY.PDP_PRICE_CARD_VIEW_PRICE_GUIDE]: 'Excluding govt. charges. ',
  [MICROCOPY.PDP_PRICE_CARD_VIEW_PRICE_TEXT]: 'View price guide',
  [MICROCOPY.PDP_PRICE_CARD_IN_PROGRESS_MOBILE_LABEL]: 'In progress',
  [MICROCOPY.PDP_PRICE_CARD_IN_PROGRESS_TABLET_LABEL]: 'Purchase in progress',
  [MICROCOPY.PDP_PRICE_CARD_USE_FINANCE_DESCRIPTION]:
    'Carma can help you finance your car through an easy step-by-step journey.',
  [MICROCOPY.PDP_PRICE_CARD_USE_FINANCE_HEADING]: 'Use finance',
  [MICROCOPY.PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK]:
    'est. __{price}/wk__ 5yr term based on an average __{interestRate}% p.a.__ Fees and charges apply*',
  [MICROCOPY.PDP_PRICE_CARD_USE_FINANCE_REPAYMENT_DESCRIPTION]: 'Refine the repayment now',
  [MICROCOPY.PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_HEADLINE]: 'Weekly payment of {price}',
  [MICROCOPY.PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_DESCRIPTION]:
    'The weekly payment of {price} is based on payment over a 5 year term at an average rate of {interestRate} p.a. Fees and charges apply * ',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_LABEL_BUTTON]: 'Buy now ',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_CARD_TITLE]: 'Pay outright',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_CARD_DESCRIPTION]: 'We accept all major Australian credit and debit cards ',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_GOVT_CHARGES_TEXT]: 'Excluding govt. charges',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_AMERICAN_EXPRESS_TEXT]: 'American Express',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_VISA_TEXT]: 'visa',
  [MICROCOPY.PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_MASTER_CARD_TEXT]: 'master card',
  [MICROCOPY.PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFO]: 'View purchase info',
  [MICROCOPY.PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFORMATION]: 'View purchase information',
  [MICROCOPY.PDP_IMAGES_TAKE_IT_FOR_A_SPIN]: 'Take it for a spin',
  [MICROCOPY.PDP_PRICE_CARD_USP_ITEM_1]: 'We throughly inspect and recondition all cars.',
  [MICROCOPY.PDP_PRICE_CARD_USP_ITEM_2]: 'Deliverd direct to your door, for free.',
  [MICROCOPY.PDP_PRICE_CARD_USP_ITEM_3]: 'All cars come with 3 months registration.',
  [MICROCOPY.PLP_FILTER_MAKE_MODEL_ALL]: 'All models',
  [MICROCOPY.PLP_FILTER_MAKE_MODEL_SELECTED]: 'Model Selected',
  [MICROCOPY.PLP_FILTER_MAKE_MODEL_SEARCH_LABEL]: 'Search make',
  [MICROCOPY.PLP_FILTER_MAKE_MODEL_SHOW_ALL]: 'Show all makes',
  [MICROCOPY.PLP_FILTER_MAKE_MODEL_SHOW_POPULAR]: 'Show popular makes only',
  [MICROCOPY.PDP_GALLERY_MODAL_QUALITY]: pdpGalleryModalQuality,
  [MICROCOPY.PDP_GALLERY_TITLE]: 'Gallery',
  [MICROCOPY.PDP_GALLERY_BUTTON_GALLERY]: 'Gallery',
  [MICROCOPY.PDP_GALLERY_BUTTON_IMPERFECTION]: 'Imperfections',
  [MICROCOPY.PDP_GALLERY_BUTTON_INTERIOR]: 'Interior',
  [MICROCOPY.PDP_GALLERY_BUTTON_EXTERIOR]: 'Exterior',
  [MICROCOPY.PDP_CAR_HERO_BUTTON_OPEN_DOORS]: 'Open doors',
  [MICROCOPY.PDP_CAR_HERO_BUTTON_CLOSE_DOORS]: 'Close doors',
  [MICROCOPY.PDP_GALLERY_BUTTON_VIEW_360]: 'View 360',
  [MICROCOPY.PDP_IMPERFECTIONS_BUTTON_NEXT]: 'Next',
  [MICROCOPY.PDP_IMPERFECTIONS_BUTTON_PREVIOUS]: 'Previous',
  [MICROCOPY.PDP_GALLERY_NO_IMPERFECTIONS]: pdpGalleryNoImperfections,
  [MICROCOPY.PDP_GALLERY_NO_IMPERFECTIONS_MOBILE]: pdpGalleryNoImperfectionsMobile,
  [MICROCOPY.PDP_WARRANTY_TITLE]: 'Remaining manufacturer warranty*',
  [MICROCOPY.PDP_WARRANTY_OTHER_BRANDS_TITLE]: 'Remaining manufacturer warranty',
  [MICROCOPY.PDP_WARRANTY_INFORMATION]: '{remainingYearsAndMonths} or until {warrantyKms} (whichever comes first)',
  [MICROCOPY.PDP_WARRANTY_INFORMATION_UNLIMITED_KILOMETRES]: '{remainingYearsAndMonths} & unlimited KMs',
  [MICROCOPY.PDP_WARRANTY_EXTENDED_MANUFACTURER]:
    '*This car may also be eligible for extended manufacturer warranty, please check with {make}.',
  [MICROCOPY.PDP_REMAINING_WARRANTY_ELIGIBLE_CONTENT]: 'Additional 3 years protection for $1,790.',
  [MICROCOPY.PDP_ONLY_ELIGIBLE_CONTENT]: 'Eligible for 3 years protection for $1,790.',
  [MICROCOPY.PDP_WARRANTY_MODAL_LINK_LABEL]: 'Learn more',
  [MICROCOPY.PDP_WARRANTY_MODAL_LINK]: 'https://carma.com.au/extended-coverage',
  [MICROCOPY.PDP_WARRANTY_MODAL_TITLE]: 'Carma Extended Coverage',
  [MICROCOPY.PDP_WARRANTY_MODAL_SUBTEXT_1]:
    'For a one-time payment of $1,790 drive with confidence knowing your car is protected.',
  [MICROCOPY.PDP_WARRANTY_MODAL_SUBTEXT_2]: 'Add Carma Extended Coverage to your order when you’re reserving your car.',
  [MICROCOPY.PDP_WARRANTY_MODAL_ITEM_TITLE_1]: '3 years mechanical protection',
  [MICROCOPY.PDP_WARRANTY_MODAL_ITEM_TEXT_1]:
    'Get the comfort of knowing you’ll be protected in the event of an unexpected mechanical failure for 3 additional years.',
  [MICROCOPY.PDP_WARRANTY_MODAL_ITEM_TITLE_2]: 'Min 3 years Premium roadside assist',
  [MICROCOPY.PDP_WARRANTY_MODAL_ITEM_TEXT_2]:
    'Nationwide emergency breakdown help starting from the delivery / collection of your car.',
  [MICROCOPY.PDP_WARRANTY_MODAL_BUTTON_LABEL]: 'More about Carma Extended Coverage',
  [MICROCOPY.PDP_CAR_ENQUIRY_CONTENT_BLOCK_TITLE]: 'Have a question about this car?',
  [MICROCOPY.PDP_CAR_ENQUIRY_CONTENT_BLOCK_DESCRIPTION]: 'Send us a message',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_HEADER]: 'Got a question?',
  [MICROCOPY.PDP_CAR_ENQUIRY_SUCCESS_FORM_HEADER]: 'Your message has been sent successfully',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_MESSAGE_FIELD_LABEL]: 'Add your message',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL]: 'Email address',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_PHONE_NUMBER_LABEL]: 'Phone number',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_TRADEIN_INTENT_LABEL_LABEL]: 'I have a car to trade in',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_IS_FINANCE_INTEREST_LABEL]: "I'm interested in Carma finance",
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL]: 'Submit',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE]: 'We will review your message and get back to you shortly',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE_BUTTON_LABEL]: 'Continue browsing',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_DEFAULT_MESSAGE]: 'I am interested in finding out more about this car.',
  [MICROCOPY.PDP_CAR_ENQUIRY_FORM_ERROR_MESSAGE]:
    "Sorry, we couldn't submit your message at this time. Please give us a call on 0283193210 or email us at hello@carma.com.au",
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_HEADER]: 'Get in touch with a finance professional',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL]: 'Add your message',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE]: 'Your details',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL]: 'Email address',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL]: 'Phone number (optional)',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL]: 'Submit enquiry',
  [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE]:
    "Sorry, we couldn't submit your message at this time. Please give us a call on 0283193210 or email us at hello@carma.com.au",
  [MICROCOPY.PDP_HEADER_SHARE_BUTTON_LABEL]: 'Share',
  [MICROCOPY.PDP_CAR_DETAILS_USP_COLUMN_1_LABEL]: '7 day money back guarantee',
  [MICROCOPY.PDP_CAR_DETAILS_USP_COLUMN_2_LABEL]: '3 month warranty',
  [MICROCOPY.PDP_CAR_DETAILS_USP_COLUMN_3_LABEL]: 'Examined by experts',
  [MICROCOPY.PDP_CAR_DETAILS_USP_COLUMN_4_LABEL]: 'Free delivery to your door',
  [MICROCOPY.PDP_INSPECTION_LIST_TITLE]: '300 point inspection',
  [MICROCOPY.PDP_INSPECTION_LIST_DESCRIPTION]:
    'We put every vehicle through a 300 point inspection so that you can be 100% confident in the quality and safety of your vehicle.',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_1]: 'Driver comfort',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_2]: 'Exterior',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_3]: 'Interior',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_4]: 'Mechanical',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_5]: 'Road test',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_6]: 'Safety',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_7]: 'Technology',
  [MICROCOPY.PDP_INSPECTION_LIST_ITEM_8]: 'Vehicle data',
  [MICROCOPY.PDP_INSPECTION_LIST_CTA]: 'See entire 300 point inspection',
  [MICROCOPY.PDP_GET_STARTED_TITLE]: 'Let’s get this show on the road',
  [MICROCOPY.PDP_GET_STARTED_DESCRIPTION]:
    'If you’ve found the perfect car, log in, or if you’re new to Carma, create an account, and let’s get things moving.',
  [MICROCOPY.PDP_GET_STARTED_ITEM_1]: pdpGetStartedItem1,
  [MICROCOPY.PDP_GET_STARTED_ITEM_2]: pdpGetStartedItem2,
  [MICROCOPY.PDP_GET_STARTED_ITEM_3]: pdpGetStartedItem3,
  [MICROCOPY.PDP_CAR_OVERVIEW_TITLE]: 'Key Highlights',
  [MICROCOPY.PDP_KEY_INFORMATION_TITLE]: 'Key information',
  [MICROCOPY.PDP_KEY_INFORMATION_KILOMETERS]: `Kilometres`,
  [MICROCOPY.PDP_KEY_INFORMATION_COMPLIANCE_YEAR]: `Compliance year`,
  [MICROCOPY.PDP_KEY_INFORMATION_BUILD_YEAR]: `Build year`,
  [MICROCOPY.PDP_KEY_INFORMATION_ENGINE]: `Engine`,
  [MICROCOPY.PDP_KEY_INFORMATION_FUEL_TYPE]: `Fuel type`,
  [MICROCOPY.PDP_KEY_INFORMATION_TRANSMISSION]: `Transmission`,
  [MICROCOPY.PDP_KEY_INFORMATION_SEATS]: `Seats`,
  [MICROCOPY.PDP_KEY_INFORMATION_LAST_SERVICE_DATE]: `Last service date`,
  [MICROCOPY.PDP_KEY_INFORMATION_VIN_NUMBER]: `VIN`,
  [MICROCOPY.PDP_CART_SWITCH_TITLE]: 'So what’ll it be?',
  [MICROCOPY.PDP_CART_SWITCH_DESCRIPTION]:
    'Looks like you’ve already got a purchase under way, which one do you want to progress?',
  [MICROCOPY.PDP_CART_SWITCH_NEW_PURCHASE]: pdpCartSwitchNewPurchase,
  [MICROCOPY.PDP_CART_SWITCH_EXISTING_PURCHASE]: pdpCartSwitchExistingPurchase,
  [MICROCOPY.PDP_SIMILAR_CARS_TITLE]: 'Other cars you might like',
  [MICROCOPY.PDP_CARMA_CORE_VALUE_PROPS_TITLE_1]: 'Examined by experts',
  [MICROCOPY.PDP_CARMA_CORE_VALUE_PROPS_TITLE_2]: '3 month warranty',
  [MICROCOPY.PDP_CARMA_CORE_VALUE_PROPS_TITLE_3]: '7 day money back guarantee',
  [MICROCOPY.PDP_CARMA_CORE_VALUE_PROPS_TITLE_4]: 'Free delivery to your door',
  [MICROCOPY.PDP_CAR_OVERVIEW_REGO]: '{reference} month{plural} registration left',
  [MICROCOPY.PDP_CAR_OVERVIEW_LOW_KM_YEAR]: 'Low kms per year',
  [MICROCOPY.PDP_CAR_OVERVIEW_LOW_KM]: 'Low kms',
  [MICROCOPY.PDP_CAR_OVERVIEW_LAST_SERVICED]: 'Serviced within last {reference} month{plural}',
  [MICROCOPY.PDP_CAR_OVERVIEW_SINGLE_OWNER]: 'Single owner',
  [MICROCOPY.PDP_CAR_OVERVIEW_FULL_SPARE_TYRE]: 'Spare tyre',
  [MICROCOPY.PDP_CAR_OVERVIEW_TWO_NEW_TYRES]: '2 New tyres',
  [MICROCOPY.PDP_CAR_OVERVIEW_FOUR_NEW_TYRES]: '4 New tyres',
  [MICROCOPY.PDP_CARMA_COLLECT_LOCATIONS_TITLE]: 'See our locations',
  [MICROCOPY.PDP_CARMA_COLLECT_LOCATIONS_RADIOS_TITLE]: 'Choose a collection location',
  [MICROCOPY.PDP_SPECS_AND_FEATURES_HEADER]: 'Specs & Features',
  [MICROCOPY.PDP_SPECS_AND_FEATURES_CTA]: 'View all specs & features',
  [MICROCOPY.PDP_FEATURES_HEADER]: 'Features we think you’ll love',
  [MICROCOPY.PDP_SPECS_HEADER]: 'Specs',
  [MICROCOPY.PDP_FEATURES_CTA]: 'View all {total} features',
  [MICROCOPY.PDP_SPECS_CTA]: 'View all specs',
  [MICROCOPY.PDP_SERVICE_HISTORY_HEADER]: 'Last service',
  [MICROCOPY.PDP_SERVICE_HISTORY_SECTION_HEADER]: 'Service history and reconditioning',
  [MICROCOPY.PDP_SERVICE_HISTORY_ONE_SERVICE_HEADER]: 'This car has only had one service',
  [MICROCOPY.PDP_SERVICE_HISTORY_LIKE_NEW_HEADER]: 'This car is close to brand new and hasn’t been serviced yet.',
  [MICROCOPY.PDP_SERVICE_HISTORY_BUTTON_SHOW_LABEL]: 'View full service history',
  [MICROCOPY.PDP_SERVICE_HISTORY_BUTTON_HIDE_LABEL]: 'Hide service history',
  [MICROCOPY.PDP_SERVICE_HISTORY_NO_SERVICE_DATE_MESSAGE]: 'Date NA',
  [MICROCOPY.PDP_SERVICE_HISTORY_NO_KILOMETRE_MESSAGE]: 'Odometer NA',
  [MICROCOPY.PDP_TRADE_IN_HEADER]: 'Trade and Upgrade',
  [MICROCOPY.PDP_TRADE_IN_DESCRIPTION]:
    'Unlock the value of your old car. Get a quick quote today and upgrade to your dream car.',
  [MICROCOPY.PDP_TRADE_IN_BUTTON_SUBMIT_LABEL]: 'Go',

  //
  //
  // Trade-in form microcopy
  //
  //
  [MICROCOPY.TRADE_IN_CAR_FOUND_TITLE]: 'We think we’ve found your car!',
  [MICROCOPY.TRADE_IN_CAR_FOUND_DESCRIPTION]:
    'Is this the car you want to sell? Please take a moment to review the details below.',
  [MICROCOPY.TRADE_IN_CAR_FOUND_HINT]:
    'Hint - Make sure these details are correct. If they are incorrect make sure your registration plate or VIN was entered correctly.',
  [MICROCOPY.TRADE_IN_CAR_NOT_FOUND_TITLE]: 'Please enter your car details to get started',
  [MICROCOPY.TRADE_IN_EDIT_CAR_DETAILS_TITLE]: 'Please update with your correct car details',
  [MICROCOPY.TRADE_IN_PERSONAL_INFO_TITLE]: 'Tell us about yourself',
  [MICROCOPY.TRADE_IN_PERSONAL_INFO_DESCRIPTION]:
    "Please provide your details. We'll use this information to reach out to you with any questions and an offer for your car.",
  [MICROCOPY.TRADE_IN_INTRO_TITLE]: 'A quick rundown',
  [MICROCOPY.TRADE_IN_INTRO_CONTENT]: tradeInIntroContent,
  [MICROCOPY.TRADE_IN_BASIC_INFO_TITLE]: 'Let’s start with the basics',
  [MICROCOPY.TRADE_IN_TIMELINE_TITLE]: 'What’s your timeline?',
  [MICROCOPY.TRADE_IN_TYRE_WEAR_TITLE]: 'Tyre wear',
  [MICROCOPY.TRADE_IN_VALUATION_TITLE]: 'Expected valuation',
  [MICROCOPY.TRADE_IN_EXTERIOR_TITLE]: 'Exterior condition',
  [MICROCOPY.TRADE_IN_EXTERIOR_FIELD_LABEL]: 'Does your car have any of the following?',
  [MICROCOPY.TRADE_IN_EXTERIOR_FIELD_SUBLABEL]: tradeInExteriorFieldSublabel,
  [MICROCOPY.TRADE_IN_EXTERIOR_HAIL_TITLE]: 'Hail damage',
  [MICROCOPY.TRADE_IN_EXTERIOR_HAIL_LABEL]: 'Is there any hail damage on your car?',
  [MICROCOPY.TRADE_IN_EXTERIOR_EXTERIOR_DAMAGE_TITLE]: 'Exterior damage',
  [MICROCOPY.TRADE_IN_EXTERIOR_MINOR_DAMAGE_LABEL]: 'How many panels have minor damage only?',
  [MICROCOPY.TRADE_IN_EXTERIOR_MINOR_DAMAGE_SUBLABEL]:
    'Includes broken paint from shallow dents, scuffs or scratches larger than 3cm (or a 20c coin).',
  [MICROCOPY.TRADE_IN_EXTERIOR_MAJOR_DAMAGE_LABEL]: 'How many panels have any major damage?',
  [MICROCOPY.TRADE_IN_EXTERIOR_MAJOR_DAMAGE_SUBLABEL]:
    'Includes dents deeper than 5cm (with or without broken paint) or heavily impacted panels.',
  [MICROCOPY.TRADE_IN_EXTERIOR_WHEEL_DAMAGE_TITLE]: 'Wheel damage',
  [MICROCOPY.TRADE_IN_EXTERIOR_WHEEL_DAMAGE_LABEL]: 'How many wheels have scratches or scrapes on the rims?',
  [MICROCOPY.TRADE_IN_EXTERIOR_WINDSCREEN_TITLE]: 'Windscreen damage',
  [MICROCOPY.TRADE_IN_EXTERIOR_WINDSCREEN_CRACKS_LABEL]: 'Does the windscreen have any cracks?',
  [MICROCOPY.TRADE_IN_EXTERIOR_WINDSCREEN_CHIPS_LABEL]: 'Does the windscreen have any chips in the driver’s vision?',
  [MICROCOPY.TRADE_IN_INTERIOR_FIELD_LABEL]: 'Does your car have any of the following?',
  [MICROCOPY.TRADE_IN_INTERIOR_FIELD_SUBLABEL]: tradeInInteriorFieldSublabel,
  [MICROCOPY.TRADE_IN_INTERIOR_TITLE]: 'Interior condition',
  [MICROCOPY.TRADE_IN_INTERIOR_RIPS_TEARS_TITLE]: 'Rips & tears',
  [MICROCOPY.TRADE_IN_INTERIOR_STAINS_TITLE]: 'Stains',
  [MICROCOPY.TRADE_IN_INTERIOR_DIGITAL_SCREEN_TITLE]: 'Digital screens',
  [MICROCOPY.TRADE_IN_INTERIOR_ODOUR_TITLE]: 'Persistent odours',
  [MICROCOPY.TRADE_IN_CAR_HISTORY_TITLE]: 'Car history',
  [MICROCOPY.TRADE_IN_INTERIOR_DAMAGE_TO_PANEL_OR_DASHBOARD]: 'Damage to interior panelling / dashboard',
  [MICROCOPY.TRADE_IN_MISSED_ANYTHING_TITLE]: 'Have we missed anything?',
  [MICROCOPY.TRADE_IN_MISSED_ANYTHING_SUBTITLE]:
    'Before we move on, is there anything else we should know about your car’s mechanical, exterior or interior condition? If you’re unsure about any possible issues, it’s best to mention them here. (Optional)',
  [MICROCOPY.TRADE_IN_FACTORY_OPTIONS_TITLE]: 'Factory installed options',
  [MICROCOPY.TRADE_IN_FACTORY_OPTIONS_LABEL]: 'Are there any factory installed options?',
  [MICROCOPY.TRADE_IN_FACTORY_OPTIONS_SUBLABEL]:
    'These are optional features selected when the vehicle was first purchased from the manufacturer.',
  [MICROCOPY.TRADE_IN_APPLICABLE_OPTIONS_LABEL]: 'Select the applicable installed options',
  [MICROCOPY.TRADE_IN_APPLICABLE_OPTIONS_SUBLABEL]:
    "These options have been retrieved based on your car's make and model.",
  [MICROCOPY.TRADE_IN_OTHER_OPTIONAL_EXTRAS_LABEL]: 'Type your factory installed options and press enter to add.',
  [MICROCOPY.TRADE_IN_MODIFICATIONS_TITLE]: 'Modifications and aftermarket accessories',
  [MICROCOPY.TRADE_IN_MODIFICATIONS_RADIO_FIELD_LABEL]: 'Are there any modifications or aftermarket accessories?',
  [MICROCOPY.TRADE_IN_MODIFICATIONS_RADIO_FIELD_SUBLABEL]:
    'These are changes or feature additions made to the car after it was purchased from the manufacturer.',
  [MICROCOPY.TRADE_IN_MODIFICATIONS_TAG_FIELD_LABEL]:
    'Type your modification or aftermarket accessory and hit enter to add',
  [MICROCOPY.TRADE_IN_SERVICE_HISTORY_TITLE]: 'Service history',
  [MICROCOPY.TRADE_IN_SERVICE_HISTORY_LABEL]:
    'Do you have records of each required service as specified by the manufacturer?',
  [MICROCOPY.TRADE_IN_SERVICE_HISTORY_SUBLABEL]:
    'According to your manufacturer, the car needs servicing every {service_kms} kms or {service_months} months, whichever occurs first.',
  [MICROCOPY.TRADE_IN_SERVICE_COMPLETED_FRANCHISED_LABEL]:
    'Have all services been completed by a manufacturer franchised dealership?',
  [MICROCOPY.TRADE_IN_SERVICE_COMPLETED_FRANCHISED_SUBLABEL]: 'Serviced by {carMake}',
  [MICROCOPY.TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_LABEL]:
    'For the services that have been completed, have they all been done by the manufacturer?',
  [MICROCOPY.TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_SUBLABEL]: 'Serviced by {carMake}',
  [MICROCOPY.TRADE_IN_SERVICE_MISSING_LABEL]: 'How many service records are missing?',
  [MICROCOPY.TRADE_IN_CAR_HISTORY_FIRST_OWNER_LABEL]: 'Are you the first owner?',
  [MICROCOPY.TRADE_IN_CAR_HISTORY_HOW_MANY_KEY_LABEL]: 'How many keys do you have for this car?',
  [MICROCOPY.TRADE_IN_CAR_HISTORY_IN_AN_ACCIDENT_LABEL]: 'Has your car ever been in an accident?',
  [MICROCOPY.TRADE_IN_PHOTOS_TITLE]: 'A picture is worth a thousand words',
  [MICROCOPY.TRADE_IN_PHOTOS_DESCRIPTION]: 'Get the most accurate valuation by uploading photos of your car.',
  [MICROCOPY.TRADE_IN_CAR_PHOTOS_LABEL]: 'Photos of your car (front, rear, left and right views)',
  [MICROCOPY.TRADE_IN_OPTIONS_ACCESSORIES_PHOTOS_LABEL]:
    'Photos of factory installed options, modifications or aftermarket accessories',
  [MICROCOPY.TRADE_IN_QR_TITLE]: 'Want to use your phone instead?',
  [MICROCOPY.TRADE_IN_QR_DESCRIPTION]:
    'Scan this QR code to continue from your phone. Easily upload photos right from your phone camera.',
  [MICROCOPY.TRADE_IN_EMAIL_LATER_TITLE]: 'Not ready to take photos yet?',
  [MICROCOPY.TRADE_IN_EMAIL_LATER_DESCRIPTION]: `Save your progress and we'll send an email to {email} for you to continue whenever you're ready.`,
  [MICROCOPY.TRADE_IN_EMAIL_LATER_LINK_LABEL]: 'Email me a link to continue later',
  [MICROCOPY.TRADE_IN_THANK_YOU_HEADER]: tradeInThankYouHeader,
  [MICROCOPY.TRADE_IN_THANK_YOU_DESCRIPTION_NEXT]: tradeInThankYouDescriptionNext,
  [MICROCOPY.TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE]: tradeInThankYouDescriptionMeanwhile,

  //
  //
  // Checkout microcopy
  //
  //
  [MICROCOPY.CHECKOUT_EXPIRED_MODAL_TITLE]: 'Reservation expired',
  [MICROCOPY.CHECKOUT_EXPIRED_MODAL_DESCRIPTION]:
    'Your reservation for this car has expired. You can return to the car listing page or find another car to start a new reservation.',
  [MICROCOPY.CHECKOUT_EXPIRED_MODAL_BUTTON_1_LABEL]: 'View this car again',
  [MICROCOPY.CHECKOUT_EXPIRED_MODAL_BUTTON_2_LABEL]: 'Browse cars',
  [MICROCOPY.CHECKOUT_TRADE_IN_HELP]: checkoutTradeInHelp,
  [MICROCOPY.CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA]: checkoutFinanceHasTradeInCarma,
  [MICROCOPY.CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS]: checkoutFinanceHasTradeInPayWithFunds,
  [MICROCOPY.CHECKOUT_TRADE_IN_DESCRIPTION]:
    'To trade-in your car, pay a $500 refundable reservation fee to hold your car. We’ll then contact you to provide you with your trade-in offer.',
  [MICROCOPY.CHECKOUT_TRADE_IN_TITLE]: 'Trade-in',
  [MICROCOPY.CHECKOUT_TRADE_IN_CONTENT]:
    'Trade in your old car when you buy your new one. We make the process hassle-free.',
  [MICROCOPY.CHECKOUT_TRADE_IN_BANNER_ITEM_1]: 'Simple. We’ll collect it from you.',
  [MICROCOPY.CHECKOUT_TRADE_IN_BANNER_ITEM_2]: 'Fair and accurate valuations.',
  [MICROCOPY.CHECKOUT_TRADE_IN_RADIO_LABEL]: 'Do you want to trade in your existing car?',
  [MICROCOPY.CHECKOUT_TRADE_IN_RADIO_ITEM_1_LABEL]: checkoutStep4TradeInFirstOption,
  [MICROCOPY.CHECKOUT_TRADE_IN_RADIO_ITEM_2_LABEL]: checkoutStep4TradeInSecondOption,
  [MICROCOPY.CHECKOUT_TRADE_IN_BUTTON_LABEL]: 'Next: Payment options',
  [MICROCOPY.CHECKOUT_STEP_1_LABEL_CONTENT]: 'Create an account',
  [MICROCOPY.CHECKOUT_STEP_1_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_STEP_2_LABEL_CONTENT]: 'Buyer details',
  [MICROCOPY.CHECKOUT_STEP_2_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_STEP_3_LABEL_CONTENT]: 'Trade-in',
  [MICROCOPY.CHECKOUT_STEP_3_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_STEP_4_LABEL_CONTENT]: 'Payment options',
  [MICROCOPY.CHECKOUT_STEP_4_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_STEP_5_LABEL_CONTENT]: 'Delivery details',
  [MICROCOPY.CHECKOUT_STEP_5_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_STEP_6_LABEL_CONTENT]: 'Reserve & pay',
  [MICROCOPY.CHECKOUT_STEP_6_CAPTION_CONTENT]: '2 mins',
  [MICROCOPY.CHECKOUT_CONFIRMATION_TRADE_IN_CARD]: checkoutConfirmationTradeInCard,
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_TITLE]:
    'We’ll send through the following purchase documents shortly for your reference and e-signature:',
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST]: checkoutConfirmationDeliveryDocumentList,
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_DESCRIPTION]:
    'We’ll also share our 7-day money-back guarantee, along with our terms and conditions and privacy policy.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_PAID_FULL_TITLE]: 'You’ve reached the finish line. </br> Get excited.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_RESERVATION_TITLE]:
    'Your reservation is complete. </br> We’ll be in touch very soon.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_PAID_FULL_DESCRIPTION]:
    'All done! We’ve received your order and are busy getting your car ready for delivery.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_1]:
    'All done! Your car is being held and we will contact you shortly to complete your payment.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_2]:
    'All done! Your car is being held and we will contact you shortly to talk through your trade-in.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_3]:
    'All done! Your car is being held and we will contact you shortly to discuss your finance quote and financier options.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_4]:
    'All done! Your car is being held and we will contact you shortly to discuss your existing financing.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_TIME_DESCRIPTION]:
    'We will organise your preferred delivery time over the phone.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_COLLECTION_TIME_DESCRIPTION]:
    'We will organise your preferred collection time over the phone.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_ADDRESS_DESCRIPTION]: 'The delivery address is <strong>{address}</strong>',
  [MICROCOPY.CHECKOUT_CONFIRMATION_COLLECTION_DESCRIPTION]:
    'The collection address is <strong>Unit 5, 1-3 Burrows Rd, St Peters NSW 2044</strong>',
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY]: checkoutConfirmationNextActionOwnFundsDelivery,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION]: checkoutConfirmationNextActionOwnFundsCollection,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY]:
    checkoutConfirmationNextActionOwnFundsTradeInDelivery,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION]:
    checkoutConfirmationNextActionOwnFundsTradeInCollection,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY]:
    checkoutConfirmationNextActionCarmaFinanceDelivery,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION]:
    checkoutConfirmationNextActionCarmaFinanceCollection,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY]:
    checkoutConfirmationNextActionCarmaFinanceTradeInDelivery,
  [MICROCOPY.CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION]:
    checkoutConfirmationNextActionCarmaFinanceTradeInCollection,
  [MICROCOPY.CHECKOUT_CONFIRMATION_DELIVERY_NOTICE]:
    'We will organise your preferred delivery date and time over the phone. If you need to make any changes please contact us up to 24 hours before delivery.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_COLLECTION_NOTICE]:
    'We will organise your preferred collection data and time over the phone. If you need to make any changes please contact us up to 24 hours before collection.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_CARMA_DETAILS]: checkoutConfirmationCarmaDetails,
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_TITLE]: `What's next?`,
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_1]:
    'Your need to provide your financier with our details so they can get in touch with us.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_2]:
    'Once the funds have been received from your financier you will be able to pay any remaining funds (if applicable).',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_3]:
    'We will then call you to discuss the delivery of your car, prepare the car and deliver it to your door.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_4]:
    'We’ll then organise your preferred collection time and prepare the car for collection.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_1]:
    'Our buying team will give you a call within 24 hours to provide you with your trade-in offer.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_2]:
    'You’ll need to provide your financier with our details so they can get in touch with us.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_3]:
    'Once the funds have been received from your financier you’ll be able to pay any remaining funds (if applicable).',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_4]:
    'Once the funds have been received from your financier you’ll be able to pay any remaining funds (if applicable).',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_5]:
    'We’ll then organise your preferred delivery time, prepare the car, and deliver it to your door. Upon delivery we’ll pick up your trade-in.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_6]:
    'We’ll then organise your preferred collection time and prepare the car for collection. Upon arrival we’ll collect your trade-in.',
  [MICROCOPY.CHECKOUT_CONFIRMATION_CTA]: 'Take me to my account',
  [MICROCOPY.CHECKOUT_DESCRIPTION_TERM_POLICY_DESCRIPTION]:
    'You’ll also find full detail of your 7 day money-back guarantee, along with our terms and conditions and privacy policy.',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_ALLICATION_TYPE]: 'Please enter your application type',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_RESIDENTIAL_STATUS]: 'Please enter your residential status',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_FINANCE_DEPOSIT_AMOUNT]: 'Please enter your finance deposit amount',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_3000]:
    'The total finance loan amount needs to be more than $3,000',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_MORE_THAN_200000]:
    'The total finance loan amount can not be more than $200,000',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_TERM_OF_LOAN]: 'Please enter your term of loan',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_REPAYMENT_FREQUENCY]: 'Please enter your repayment frequency',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_YEAR]: 'Please enter your year',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_MONTH]: 'Please enter your month',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_DATE_PICKER]: 'Please enter your date of birth',
  [MICROCOPY.CHECKOUT_STEP4_PERSONAL_RATE_ERROR_LESS_THAN_18_YEAR_OLD]:
    'You need to be over 18 years of age to apply for finance',
  [MICROCOPY.CHECKOUT_HEADER_TITLE]: 'Car reserved',
  [MICROCOPY.CHECKOUT_HEADER_VIEW_DETAILS]: 'View car details',
  [MICROCOPY.CHECKOUT_HEADER_HIDE_DETAILS]: 'Hide details',
  [MICROCOPY.CHECKOUT_CHECKOUT_CAR_CARD_PRICE_LABEL]: 'Purchase price',
  [MICROCOPY.CHECKOUT_CHECKOUT_CAR_CARD_PRICE_GST_LABEL]: 'Excluding govt. charges.',
  // eslint-disable-next-line no-template-curly-in-string
  [MICROCOPY.CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE]: checkoutFinanceLoanCalculator,
  [MICROCOPY.CHECKOUT_FINANCE_LOAN_CALCULATOR_DESCRIPTION]:
    'Add any cash you want to apply to your loan. If you want to finance the whole amount enter $0.',
  [MICROCOPY.CHECKOUT_FOOTER_LINK_1]: 'Terms and conditions',
  [MICROCOPY.CHECKOUT_FOOTER_LINK_2]: 'Privacy policy',
  [MICROCOPY.CHECKOUT_FOOTER_LINK_3]: 'Privacy collection notice',
  [MICROCOPY.CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER]: checkoutStep3PaymentOptionsChooseFinancier,
  [MICROCOPY.CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER]: checkoutStep3PaymentOptionsSkipFinancier,
  [MICROCOPY.CHECKOUT_STEP3_PAYMENT_OPTIONS_NEXT_BUTTON]: 'Next',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_HEADER]: 'Your payment options',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_CONTENT]:
    'Choose your preferred payment option – using finance or your own funds. If you are considering finance, we can make it easy for you to get started with a competitive quote from our lenders.',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_RADIO_LABEL]: 'How would you like to pay?',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_1_LABEL]: checkoutStep5FirstPaymentOption,
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_2_LABEL]: checkoutStep5SecondPaymentOption,
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_3_LABEL]: checkoutStep5ThirdPaymentOption,
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL]: 'Next',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_QUOTE]: 'Next: Arrange your quote',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_FINANCE]: 'Next: Finance information',
  [MICROCOPY.CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_SUMMARY]: 'Next: Purchase summary',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LABEL]: 'Westpac',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LINK]:
    'https://banking.westpac.com.au/wbc/banking/handler?TAM_OP=login&URL=%2Fsecure%2Fbanking%2Foverview%2Fdashboard&logout=false',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LABEL]: 'National Australia Bank',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LINK]: 'https://ib.nab.com.au/nabib/index.jsp',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LABEL]: 'ANZ',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LINK]:
    'https://www.anz.com.au/ways-to-bank/internet-banking/personal/',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LABEL]: 'Commonwealth bank',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LINK]:
    'https://www.commbank.com.au/digital-banking.html?ei=mv_log-on',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_BSB]: '001122',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_TITLE]: 'Select your bank',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CAPTION]: `Carma's bank details`,
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_HELP_TEXT]: `Your bank isn't shown? That's fine - just go to your online bank to make the payment.`,
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_SUBTITLE]:
    'Click the link to be redirected to your online bank to make your payment.',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_ACC_NUMBER]: '0123456789',
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_PAID_BUTTON_LABEL]: `I've paid`,
  [MICROCOPY.CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CLOSE_BUTTON_LABEL]: 'Close',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_PROCESSING_FEE_TITLE]: 'Processing fee',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BANK_TRANSFER]: 'Bank transfer',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_FREE]: 'Free',
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_HEADER]: 'Let’s roll',
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_DESCRIPTION]: 'You’ve taken the first step towards your new ride.',
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_LABEL]: 'What are you buying for?',
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL]: checkoutStepBuyTypeFormOptionPersonal,
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS]: checkoutStepBuyTypeFormOptionBusiness,
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_BUTTON_PERSONAL]: 'Next: Buyer information',
  [MICROCOPY.CHECKOUT_STEP_BUY_TYPE_BUTTON_BUSINESS]: 'Next: Business information',
  [MICROCOPY.CHECKOUT_STEP_BUSINESS_INFO_HEADER]: 'Business information',
  [MICROCOPY.CHECKOUT_STEP_BUSINESS_INFO_DESCRIPTION]:
    'Tell us more about your business and we’ll add it to the contract of sale.',
  [MICROCOPY.CHECKOUT_STEP_BUSINESS_INFO_ABN_HELP]:
    'Quickly find your ABN by visiting the [Australian Business Register (ABR)](https://abr.business.gov.au/)',
  [MICROCOPY.CHECKOUT_STEP_BUSINESS_INFO_ABN_LABEL]: 'Business information',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_RESIDENT_TYPE_TITLE]:
    'Lastly, are you an Australian citizen or permanent resident?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_FAMILY_STATUS_HEADER]: 'Family status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_RESIDENTIAL_STATUS_HEADER]: 'Residential status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_EMPLOYMENT_STATUS_HEADER]: 'Employment status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_BUTTON_LABEL]: 'Next: Finance quotes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS]: checkoutFinanceQuoteFormTermConditions,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FREQUENCY_SWITCH_HEADER]: 'View repayment',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FREQUENCY_WEEKLY]: 'Weekly',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FREQUENCY_MONTHLY]: 'Monthly',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_HEADER]: 'Tell us about your finance lender',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_DESCRIPTION]:
    "Already organised your own finance or spoken to our finance team? Awesome, all we need is the bank or lender's details – just in case we need to communicate with them directly.",
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_LABEL]: 'Lender',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_NAME_LABEL]: 'Lender contact’s name  (optional)',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_EMAIL_LABEL]: 'Lender contact’s email  (optional)',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_MOBILE_LABEL]: 'Lender contact’s mobile  (optional)',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_OTHER_LABEL]: 'Lender name',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_CARMA_FINANCE_OPTION]: 'Carma finance',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_1]: 'Lender 1',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_2]: 'Lender 2',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_3]: 'Lender 3',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_4]: 'Lender 4',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_5]: 'Lender 5',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_6]: 'Lender 6',
  [MICROCOPY.CHECKOUT_BYOF_CARMA_FINANCE_FORM_SUBMIT_BUTTON_LABEL]: 'Next: Payment summary',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_HEADER]: 'Buyer information',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_DESCRIPTION]:
    'These details will be shown on your contract of sale. They need to match the details that appear on your drivers licence.',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_ADDITION_INFO]:
    'These next fields relate to the business director, or primary business owner.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_HEADER]: 'Your finance quotes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FORM_CONTENT]:
    'These quotes are personalised to you and the car you are purchasing.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_CTA]: 'Next: Payment summary',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_NOTE]: checkoutStep7FinanceQuotesNote,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_NOTE_PAYMENT_SUMMARY]:
    '*These quotes are indicative only and do not constitute an offer for finance. It is of general nature, based on the accuracy of the information you have provided and does not take into account other factors such as your credit history. Nothing on this website constitutes or should be interpreted to constitute legal, taxation or financial advice. Before making a decision about any of the products and services featured on this website, you should consult with your own independent legal, taxation and financial advisors who can advise you about your personal circumstances. Conditions, fees and charges apply to approved applicants. Finance is provided by Allied Retail Finance Pty Ltd trading as Automotive Finance ABN 31 609 859 985 Australian Credit Licence 483211. *Fees and charges apply. Repayments are estimates only, based on a personalised interest rate supplied by the financier based on your inputs. Final rate and all applications subject to financier assessment and approval.”',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_UPDATE_FINANCE_INFO_BUTTON_LABEL]: 'I want to update my finance information',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FEE_LABEL]: checkoutStep7FinanceQuotesFeeLabel,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_DETAILED_SETUP_FEES]: 'Establishment fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_DETAILED_MONTHLY_FEES]: 'Monthly fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_DETAILED_EARLY_TERMINATION_FEES]: 'Early Termination fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA]: checkoutFinanceQuoteProviderCarma,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_LOADING_LABEL]: 'Hang out while we load your quotes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_LOAN_TERM_HEADER]: 'Loan term: {term} years',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_LOAN_TERM_NO_QUOTE_AVAILABLE_HEADER]: 'Loan term',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_LOAN_TERM_DESCRIPTION]: 'Slide between {min}-{max} years',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FEE_BREAKDOWN_CTA]: 'See fee breakdown',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE]: checkoutStep7FinanceQuotesAutomotiveUnavailable,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE]: checkoutStep7FinanceQuotesPlentiBusinessUnavailable,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_UNAVAILABLE]: checkoutStep7FinanceQuotesPlentiPersonalUnavailable,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE]: checkoutStep7FinanceQuotesWisrBusinessUnavailable,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_UNAVAILABLE]: checkoutStep7FinanceQuotesWisrPersonalUnavailable,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_ALLIED_PERSONAL_HAS_EARLY_TERMINATION_FEE]: 'Yes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_ALLIED_BUSINESS_HAS_EARLY_TERMINATION_FEE]: 'Yes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_HAS_EARLY_TERMINATION_FEE]: 'No',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_HAS_EARLY_TERMINATION_FEE]: 'Yes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_HAS_EARLY_TERMINATION_FEE]: 'No',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_HAS_EARLY_TERMINATION_FEE]: 'N/A',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_UNAVAILABLE_LABEL]: 'Quote unavailable',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_ALLIED_LABEL]:
    'This lender does not support your Visa Subclass or too close to visa expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_PLENTI_LABEL]:
    'This lender does not support your Visa Subclass or too close to visa expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_WISR_LABEL]:
    'This lender does not support your Visa Subclass or too close to visa expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_ALLIED_LABEL]: 'Your visa is too close to its expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_PLENTI_LABEL]: 'Your visa is too close to its expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_WISR_LABEL]: 'Your visa is too close to its expiry date',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_ALLIED_LABEL]: 'This lender does not support your Visa Subclass',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_PLENTI_LABEL]: 'This lender does not support your Visa Subclass',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_WISR_LABEL]: 'This lender does not support your Visa Subclass',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_ALLIED_LABEL]:
    'This lender does not support non-permanent residents',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_PLENTI_LABEL]:
    'This lender does not support non-permanent residents',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_WISR_LABEL]:
    'Wisr only supports Australian citizen and permanent residents',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_ALLIED_LABEL]:
    'This lender does not support business applicants',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_PLENTI_LABEL]:
    'This lender does not support business applicants',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_WISR_LABEL]:
    'This lender does not support business applicants',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_HEADER]: 'Fee breakdown',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_1]: 'Interest rate ',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_2]: 'Repayment per month',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_3]: 'Includes all fees listed below',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_4]: 'Finance amount',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_5]: 'Purchase price',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_6]: 'Total deposit',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_7]: 'Establishment fees',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_8]: 'Establishment fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_9]: 'PPSR fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_10]: 'Introducer fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_11]: 'Monthly fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_12]: 'Total amount financed',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_13]: 'Early termination fee',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_14]: 'Up to $1000 for the first two years. ',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_DISCOUNTED_LABEL]: 'Discounted',
  [MICROCOPY.CHECKOUT_FINANCE_INFORMATION_MODAL_TITLE]: 'Are you sure',
  [MICROCOPY.CHECKOUT_FINANCE_INFORMATION_MODAL_DESCRIPTION]:
    'Updating your finance information will retrieve a new set of quotes. <br/> <br/> Do you want to continue?',
  [MICROCOPY.CHECKOUT_FINANCE_INFORMATION_MODAL_BACK_BUTTON]: 'Go Back',
  [MICROCOPY.CHECKOUT_FINANCE_INFORMATION_MODAL_CONTINUE_BUTTON]: 'Save',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_PERSONAL_EARLY_TERMINATION_FEE]:
    '5% of the Outstanding balance to a max of $1,000',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_BUSINESS_EARLY_TERMINATION_FEE]:
    '5% of the Outstanding balance to a max of $1,000',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_PERSONAL_EARLY_TERMINATION_FEE]: 'No Early Repayment Fees',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_BUSINESS_EARLY_TERMINATION_FEE]: `
  <p>No early termination fee after the first two years on all loans</p>
  <ul>
    <li>Loans > 36 months - 2 monthly payments in the first 24 months</li>
    <li>Loans < 36 months - 1 monthly payment up until the first halfway point of the loan</li>
  </ul>
  `,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_WISR_PERSONAL_EARLY_TERMINATION_FEE]: 'No Early Repayment Fees',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_WISR_BUSINESS_EARLY_TERMINATION_FEE]: 'N/A',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_TITLE]:
    'Don’t despair! We may still be able to assist you with finance',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_PERSONALIZED_DESCRIPTION]:
    'We may be able to refer your enquiry to someone who could potentially assist. If you’re interested, one of our friendly finance experts can call and talk you through your options.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CHECKOUT_DESCRIPTION]:
    'Continue with finance and one of our friendly finance experts will contact you assist in finding a quote, or you can choose to change payment method.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BUTTON_CHANGE_LABEL]: 'Change payment method',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_REQUEST_CALLBACK_LABEL]: 'Request a callback',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CONTINUE_WITH_FINANCE_LABEL]: 'Continue with finance',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BACK_TO_HOME_LABEL]: 'Back to home',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_TITLE]: 'We’ll call you soon',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_CONTENT]:
    'One of our friendly finance team members will call you to help you find a personalised quote and to answer any questions you might have. Hold tight!',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_BUTTON_LABEL]: 'Return home',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUTTON_LABEL]: 'Next: Delivery or collection',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_FORM_HEADER]: 'Details from your drivers licence',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_MIDDLE_NAME_LABEL]: 'Middle name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_DATE_OF_BIRTH_LABEL]: 'Date of birth',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_STREET_ADDRESS_LABEL]: 'Street Address',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_APARTMENT_LABEL]: 'Apartment, suite, etc (optional)',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_SUBURB_LABEL]: 'Suburb',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_POSTCODE_LABEL]: 'Postcode',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_STATE_LABEL]: 'State',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_LABEL]: 'Licence number',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_HELPER]: 'Need help finding your licence number?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_HEADER]: 'Arrange your quote',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_DESCRIPTION]:
    'Easily find finance through one of our partner finance lenders. ',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_HEADER]: 'Finance loan amount calculator',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_SUB_HEADER]:
    'Enter some details to find out your total loan amount.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_DESCRIPTION]:
    'If you are planning on adding a cash deposit, add the amount here. If you want to finance the whole amount, enter $0.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUSINESS_NOTIFICATION]:
    'These next fields relate to the business director, or primary business owner.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_HEADER]: 'Family status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SINGLE]: 'Single',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_MARRIED]: 'Married',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_WIDOWED]: 'Widowed',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SEPARATED]: 'Separated',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DIVORCED]: 'Divorced',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DE_FACTO]: 'De facto',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_OTHER]: 'Other',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_ADDRESS_HEADER]: 'Residential address',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_PREVIOUS_RESIDENTIAL_ADDRESS_HEADER]: 'Previous residential address',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_1]: 'I’m a homeowner',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_2]: 'I’m renting',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_3]: 'I’m a boarder',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_4]: 'I’m living with parents',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_5]: 'Employer supplied accommodation',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_TENANCY_AGREEMENT_HEADER]: 'Are you on the tenancy agreement?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_ADDRESS_PROPERTY_HEADER]: 'What is the address of the property?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_MORTGAGED_PROPERTY_HEADER]:
    'Do you have mortgage on the current address?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_HEADER]: 'Do you have mortgage on the current address?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_HEADER]: 'Do you own an investment property?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_MORTGAGE_HEADER]:
    'Do you have mortgage on an investment property?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_INVESTMENT_PROPERTY_HEADER]: 'Is this an investment property?',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_HEADER]: 'Employment status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_FULL_TIME]: 'Full time',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_PART_TIME]: 'Part time',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CASUAL]: 'Casual',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CONTRACT]: 'Contract',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_SELF_EMPLOYED]: 'Self-employed',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_RETIRED]: 'Retired',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_UNEMPLOYED]: 'Unemployed',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_HEADER]: 'Australian residency status',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_HEADER]: 'Temporary visa information',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DESCRIPTION]:
    'If you’re not sure, click this link to see <a href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing" target="_blank">the Australian government’s visa list.</a>',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DISCLAIMER]:
    'Note: Not all finance providers support visa holders/all visa subclasses.',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_1]: 'Australian citizen',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_2]: 'Permanent resident',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_3]: 'Temporary resident',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_OPTION_LABEL]: 'Yes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_NO_MORTGAGE_OPTION_LABEL]: 'No',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUTTON_LABEL]: 'Next: Finance quotes',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION]: checkoutFinanceQuoteInfoFormImportantInformation,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS]: checkoutFinanceQuoteInfoFormTermConditions,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS]:
    checkoutFinanceQuoteInfoFormTermConditionsForBusiness,
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_LABEL]:
    'I have read, understood and agree to the terms and conditions above',
  [MICROCOPY.CHECKOUT_FINANCE_QUOTE_INFO_FORM_NOTIFICATION]: 'Don’t worry, this won’t <br/>impact your credit score',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_HEADER]: 'Provide your driver’s licence details',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DESCRIPTION]:
    'Need help finding your licence number? <a href={link}>Click here to see an example</a>',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_BUTTON]: 'Next: Delivery or collection',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_TITLE_LABEL]: 'Title',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_MIDDLE_NAME_LABEL]: 'Middle name (optional)',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DATE_OF_BIRTH_LABEL]: 'Date of birth',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_ADDRESS_LABEL]: 'Licence Address',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_STATE_OF_ISSUE_LABEL]: 'State of issue',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_NUMBER_LABEL]: 'Licence number',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_EXPIRY_LABEL]: 'Licence expiry',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LOCATION_DESCRIPTION]:
    'If your licence has been issued outside of NSW you will need to provide an RMS account number to prove that you live in NSW. If you have any questions <a href={link}>Contact us.</a>',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUSINESS_INFORMATION_HEADER]: 'Business information',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_INFORMATION_HEADER]: 'Provide your licence information',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DESCRIPTION]:
    'Need help finding your licence number? <a href={link}>Click here to see an example</a>',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUTTON]: 'Next: Delivery or collection',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_COMPANY_NAME_LABEL]: 'Company name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_ABN_LABEL]: 'ABN',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_TITLE_LABEL]: 'Title',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_MIDDLE_NAME_LABEL]: 'Middle name (optional)',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DATE_OF_BIRTH_LABEL]: 'Date of birth',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_ADDRESS_LABEL]: 'Licence address',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_STATE_OF_ISSUE_LABEL]: 'State of issue',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_LABEL]: 'Licence number',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_EXPIRY_LABEL]: 'Licence expiry',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_DESCRIPTION]:
    'If your licence has been issued outside of NSW, you will need to provide an RMS account number to prove that you live in NSW. ',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_CONTACT_US]: 'contact us',
  [MICROCOPY.CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_SEE_EXAMPLE]: 'Click here to see an example',
  [MICROCOPY.CHECKOUT_TRADE_IN_HEADER]: 'Trade-in',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_DESCRIPTION]:
    'Choose to have us conveniently deliver your car to you, or come visit us to collect it.',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_CTA]: 'Next: Trade-in',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_ADDRESS_TITLE]: 'How would you like to receive your car?',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_DELIVERY_LABEL]: checkoutStep3DeliveryOptionsDelivery,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_COLLECTION_LABEL]: checkoutStep3DeliveryOptionsCollection,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_TITLE]:
    'Is there anything we need to know about your address?',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_TITLE]:
    'Is there anything we need to know to make your collection seamless?',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_DESCRIPTION]:
    'For example, ‘I live on a busy road or one way street’.',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_DESCRIPTION]:
    'For example, ‘I’ll be flying in to collect my car’.',
  [MICROCOPY.CHECKOUT_DELIVERY_LOADING_MESSAGE]: 'One moment - we are still validating your address.',
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_HELP]: checkoutStep3DeliveryOptionHelp,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_DELIVERABLE]: checkoutStep3DeliveryOptionDeliverable,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_FORM_NOT_DELIVERABLE]: checkoutStep3DeliveryOptionNotDeliverable,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION]: checkoutStep3DeliveryAdditionalInformation,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION]: checkoutStep3CollectionAdditionalInformation,
  [MICROCOPY.CHECKOUT_DELIVERY_OPTION_CARMA_COLLECT]: checkoutStep3CarmaCollect,

  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_HEADER]: 'Payment summary',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_DUE_HEADER]: 'Due today',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_HEADER]: 'Buyer information',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_1]: 'Name',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_2]: 'Date of birth',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_3]: 'Address',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_4]: 'Licence No.',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_HEADER]: 'Delivery or collect',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_TITLE_1]: 'Delivery address',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_COLLECT_TITLE_1]: 'Collect',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_1]: 'Delivery address',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_2]: 'Additional information',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_HEADER]: 'Trade in',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_TITLE_1]: 'Are you trading in?',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_YES_TRADE_IN]: 'Yes, I’d like to trade in.',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_NO_TRADE_IN]: 'No, I don’t need trade-in.',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_HEADER]: 'Payment option',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_TITLE_1]: 'How are you paying?',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_VALUE_1]: 'Finance',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_TITLE_1]: 'How will you be paying?',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_VALUE_1]:
    'I’ll be paying with my own funds',
  // eslint-disable-next-line no-template-curly-in-string
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_2]: 'Finance details',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_REPAYMENT]: '{repayment} based on sample {rate} p.a.',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_2]: 'Lender name',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_VALUE_2]: 'Carma finance',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_3]: "Lender contact's name",
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_4]: "Lender contact's email",
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_5]: "Lender contact's mobile",
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_3]: 'Loan term',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_COULD_NOT_DECIDE]:
    'I couldn’t decide. Let me speak to someone.',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_HEADER]: 'Billing address',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_SWITCH_LABEL]: 'Same as licence address',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_DEPOSIT]: 'Deposit amount',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_PRICE_LABEL]: 'Purchase price',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_TRANSACTION_FEE_TITLE]: 'Transaction fee',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_2]: 'Total',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_RESERVATION]:
    checkoutStepPaymentSummaryPaymentMethodReservation,
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE]: checkoutStepPaymentSummaryFinanceQuotesNote,
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_PAY_FULL_AMOUNT]:
    checkoutStepPaymentSummaryPaymentMethodPayFullAmount,
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_EDIT_BUTTON]: 'Edit',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_BUTTON]: 'Change option',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_MOBILE_BUTTON]: 'Change',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_VIEW_FULL_SUMMARY_BUTTON]: 'View full purchase summary',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_SUBMIT_BUTTON]: 'Pay reservation amount',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_PAYING_WITHOUT_FINANCE_FULL_AMOUNT_SUBMIT_BUTTON]: 'Pay full amount',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_SUBMIT_LABEL]: 'Save',
  [MICROCOPY.CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_INSTANT_FINANCE_QUOTE_HEADER]: 'Select your quote',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_HEADER]: 'Purchase documents',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_DESCRIPTION]: 'You can view and download your transaction documents here.',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_HEADER]: 'Tax invoice',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_CONTENT]: 'Available after paying remaining funds',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_FORM_5_HEADER]: 'Motor Dealer’s Notice - Form 5',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_FORM_5_CONTENT]: 'Available after the car is delivered.',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_CONTENT]: 'Available to download.',
  [MICROCOPY.CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_BUTTON_LABEL]: 'Download',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_SUBMIT_QUOTE_LABEL]: 'Resubmit quote',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_QUOTE_DETAILS_TITLE]: 'Quote details',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_REPAYMENT_AMOUNT_TITLE]: 'Repayment amount',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_LOAN_AMOUNT_LABEL]: 'Loan amount',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_DEPOSIT_LABEL]: 'Deposit',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_TERM_LABEL]: 'Term',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_WEEKLY_LABEL]: '/ weekly',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_INTEREST_RATE_LABEL]: 'Interest rate p.a.',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FEE_BREAKDOWN_LABEL]: 'See fee breakdown',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_CHECKOUT_BUTTON_LABEL]: 'Checkout with this car',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_BUILD_YEAR_TITLE]: 'Build year quote',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_BUILD_YEAR_TEXT]: 'Build year',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_GET_PRE_APPROVED_BUTTON_LABEL]: 'Get pre approved',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_BROWSE_CARS_BUTTON_LABEL]: 'Browse cars that match this quote',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_DISCOUNT_OFFER_LABEL]: 'Rate offer by',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_BUTTON_LABEL]: 'Get pre-approved',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_CONFIRMATION_LABEL]: 'Requested',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_TITLE]: 'Get pre approved for your loan',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_DESCRIPTION]:
    'Ready to take the next step? Click the button below and one of our friendly finance team members will give you call to discuss next steps and answer any questions you might have. Hold tight!',
  [MICROCOPY.CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_CTA_LABEL]: 'Request call',
  [MICROCOPY.CHECKOUT_VIEW_PURCHASE_SUMMARY_BUTTON_LABEL]: 'View full purchase summary',
  [MICROCOPY.CHECKOUT_GET_STARTED_HEADER]: 'Great choice!',
  [MICROCOPY.CHECKOUT_GET_STARTED_DESCRIPTION]: 'Don’t miss the chance to secure this car before it’s gone.',
  [MICROCOPY.CHECKOUT_GET_STARTED_OPTION_1_TITLE]: 'Do you want to use finance?',
  [MICROCOPY.CHECKOUT_GET_STARTED_OPTION_1_DESCRIPTION]: 'We offer instant personalised quotes from multiple lenders.',
  [MICROCOPY.CHECKOUT_GET_STARTED_OPTION_2_TITLE]: 'Do you have a vehicle to trade in?',
  [MICROCOPY.CHECKOUT_GET_STARTED_OPTION_2_DESCRIPTION]: "We'll collect your old car when you pick up your Carma car.",
  [MICROCOPY.CHECKOUT_GET_STARTED_INCLUDED_TEXT]: 'Included',
  [MICROCOPY.CHECKOUT_GET_STARTED_FREE_TEXT]: 'Free',
  [MICROCOPY.CHECKOUT_GET_STARTED_GUARANTEE_TEXT]: '7 day money back guarantee*',
  [MICROCOPY.CHECKOUT_GET_STARTED_WARRANTY_TEXT]: '3 month warranty*',
  [MICROCOPY.CHECKOUT_GET_STARTED_NRMA_TEXT]: '12 month NRMA Roadside Assistance*',
  [MICROCOPY.CHECKOUT_GET_STARTED_DELIVERY_TEXT]: 'Delivery within Greater Sydney. ',
  [MICROCOPY.CHECKOUT_GET_STARTED_DELIVERY_LINK]: 'Check eligibility',
  [MICROCOPY.CHECKOUT_GET_STARTED_SUBMIT_BUTTON_LABEL]: 'Continue',
  [MICROCOPY.CHECKOUT_GET_STARTED_HOLD_CAR_TEXT]: 'Continue to hold the car for',
  [MICROCOPY.CHECKOUT_GET_STARTED_HOLD_CAR_TIME]: '1 hour',
  [MICROCOPY.CHECKOUT_GET_STARTED_VIEW_TERMS]: '*View terms',
  [MICROCOPY.CHECKOUT_GET_STARTED_TERM_MODAL_TITLE]: 'Terms',
  [MICROCOPY.CHECKOUT_GET_STARTED_TERM_MODAL_CONTENT]: checkoutGetStartedViewTermsModalContents,
  [MICROCOPY.CHECKOUT_GET_STARTED_CHAT_WIDGET_LABEL]: 'Any questions? Talk to our team',
  [MICROCOPY.CHECKOUT_CHANGE_PAYMENT_TITLE]: 'Changing your payment option',
  [MICROCOPY.CHECKOUT_CHANGE_PAYMENT_DESCRIPTION]:
    'Not interested in paying with finance? Continue to complete your checkout by using your own funds.',
  [MICROCOPY.CHECKOUT_CHANGE_PAYMENT_BACK_BUTTON_LABEL]: 'Go back',
  [MICROCOPY.CHECKOUT_CHANGE_PAYMENT_CONTINUE_BUTTON_LABEL]: 'Continue',
  [MICROCOPY.CHECKOUT_CHANGE_PAYMENT_OPTION_LABEL]: 'Change your payment option',
  [MICROCOPY.MAGIC_TERM_PRIVACY]: magicTermAndPrivacy,
  [MICROCOPY.MAGIC_TERM_LOADING_TEXT]: 'We are working hard...',

  //
  //
  // Product list microcopy (PLP)
  //
  //
  [MICROCOPY.PLP_PAGE_TITLE]: 'Our cars',
  [MICROCOPY.PLP_PAGE_DESCRIPTION]:
    'We have a huge selection of quality used cars to choose from, with new stock arriving every week. Each car has been meticulously checked by an expert, so you know it’s up to scratch!',
  [MICROCOPY.PLP_PILL_PURCHASE_PROGRESS]: 'Purchase in Progress',
  [MICROCOPY.PLP_PILL_MY_PURCHASE]: 'My Purchase',
  [MICROCOPY.PLP_PILL_DRAFT_ORDER]: 'On hold',
  [MICROCOPY.PLP_PILL_RESERVED]: 'Deposit taken',
  [MICROCOPY.PLP_PRODUCT_CARD_ESTIMATE_REPAYMENT]:
    // eslint-disable-next-line no-template-curly-in-string
    'est. __${weeklyRepayment}/wk__ based on __{defaultRatePercentage} p.a.__',
  // eslint-disable-next-line no-template-curly-in-string
  [MICROCOPY.PLP_PILL_REFINE_PAYMENT_HEADLINE]: 'Weekly payment of ${weeklyRepayment}',
  [MICROCOPY.PLP_PILL_REFINE_PAYMENT_DESCRIPTION]:
    // eslint-disable-next-line no-template-curly-in-string
    'The weekly payment of ${weeklyRepayment} is based on payment over a 5 year term at an average rate of {defaultRatePercentage} p.a. Fees and charges apply^',
  [MICROCOPY.PLP_NO_RESULTS_FORM]: plpNoResultsForm,
  [MICROCOPY.PLP_SEARCH_DEFAULT_TITLE]: 'Our cars',
  [MICROCOPY.PLP_SEARCH_DEFAULT_SEO_COPY]:
    'We have a huge selection of quality used cars to choose from, with new stock arriving every week. Each car has been meticulously checked by an expert, so you know it’s up to scratch!',
  [MICROCOPY.PLP_SEARCH_MULTI_TITLE]: 'Our cars',
  [MICROCOPY.PLP_SEARCH_MULTI_SEO_COPY]:
    'We have a huge selection of quality used cars to choose from, with new stock arriving every week. Each car has been meticulously checked by an expert, so you know it’s up to scratch!',
  [MICROCOPY.PLP_SEARCH_NO_RESULT_TITLE]: '0 Results',
  [MICROCOPY.PLP_SEARCH_NO_RESULT_SEO_COPY_TITLE]: `We don’t have any matches...yet`,
  [MICROCOPY.PLP_SEARCH_NO_RESULT_SEO_COPY_DESCRIPTION]: `We get new cars every day, and chances are the car you're after will be in stock very soon!`,
  //
  //
  // Header menu microcopy
  //
  //
  [MICROCOPY.GENERAL_HEADER_PURCHASE_PROGRESS_BUTTON]: 'Continue purchase',
  [MICROCOPY.GENERAL_HEADER_TIME_PURCHASE_UNAVAILABLE]: 'Timer unvailable',
  [MICROCOPY.GENERAL_HEADER_TIME_REMAINING]: 'Time remaining',
  [MICROCOPY.GENERAL_HEADER_BUTTON_SIGN_OUT]: 'Sign out',
  [MICROCOPY.GENERAL_HEADER_SEARCH_PLACEHOLDER]: 'Search makes, models, or keywords',
  [MICROCOPY.GENERAL_HEADER_SEARCH_PLACEHOLDER_MOBILE]: 'Search',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_ALL_CTA]: 'View all cars',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_RESULT_CTA]: 'View all results for',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_CAR_SUGGESTIONS_LABEL]: 'Car Suggestions',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_CAR_JUST_ADDED_LABEL]: 'Cars just added',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_FILTER_SUGGESTIONS_LABEL]: 'Search Suggestions',
  [MICROCOPY.GENERAL_HEADER_SEARCH_DROPDOWN_RECENT_SEARCH_LABEL]: 'Recent Searches',
  [MICROCOPY.GENERAL_HEADER_LOGIN_BUTTON_LABEL]: 'Login',
  //
  //
  // Account microcopy
  //
  //
  [MICROCOPY.ACCOUNT_NAV_GREETING]: 'Hi {name}',
  [MICROCOPY.ACCOUNT_NAV_MY_PURCHASES]: 'My purchases',
  [MICROCOPY.ACCOUNT_NAV_PERSONAL_DETAILS]: 'Personal details',
  [MICROCOPY.ACCOUNT_NAV_MY_FINANCE_QUOTES]: 'My finance quotes',
  [MICROCOPY.ACCOUNT_NAV_SIGN_OUT]: 'Sign out',
  [MICROCOPY.ACCOUNT_PURCHASES_TITLE]: 'My purchases',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_NOT_SCHEDULED]: 'Delivery Not Scheduled',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_SCHEDULED]: 'Delivery in {days}',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_PREPARING_VEHICLE]: 'Delivery in {days}',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_OUT_FOR_DELIVERY]: 'Out For Delivery',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_DELIVERED]: 'Car Delivered',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECTION_STATUS_NOT_SCHEDULED]: 'Collection Not Scheduled',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECTION_STATUS_SCHEDULED]: 'Collect in {days}',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECTION_STATUS_PREPARING_VEHICLE]: 'Collect in {days}',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECTION_STATUS_OUT_FOR_COLLECTION]: 'Collect in {days}',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECTION_STATUS_COLLECTED]: 'Car Collected',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURN_REQUESTED]: 'Return Requested',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURNED]: 'Car Returned',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_STATUS_CANCELLED]: 'Order Cancelled',
  [MICROCOPY.ACCOUNT_PURCHASES_NO_CARS_PURCHASED]: 'You haven’t purchased any cars yet',
  [MICROCOPY.ACCOUNT_PURCHASES_CAR_DETAILS]: 'Car details',
  [MICROCOPY.ACCOUNT_PURCHASES_CARMA_CHECKLIST]: 'Carma checklist',
  [MICROCOPY.ACCOUNT_PURCHASES_VIEW_PURCHASE_INFO]: 'View purchase information',
  [MICROCOPY.ACCOUNT_FINANCE_TITLE]: 'Your finance quotes',
  [MICROCOPY.ACCOUNT_NOTIFICATION_TITLE]: 'Notification settings',
  [MICROCOPY.ACCOUNT_NOTIFICATION_SETTINGS]: 'Notification settings',
  [MICROCOPY.ACCOUNT_PURCHASES_CUSTOMER_SERVICE]: accountPurchasesCustomerService,
  [MICROCOPY.ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE]: accountPurchasesCustomerServiceGuarantee,
  [MICROCOPY.ACCOUNT_PURCHASES_CUSTOMER_SERVICE_T_C]: 'Terms & Conditions',
  [MICROCOPY.ACCOUNT_PURCHASES_CUSTOMER_SERVICE_PRIVACY_POLICY]: 'Privacy Policy',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS]: accountPurchasesMyPaymentProgress,
  [MICROCOPY.ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS]: accountPurchasesPayingYourRemainingFunds,
  [MICROCOPY.ACCOUNT_PURCHASES_VIEW_BANK_TRANSFER_DETAILS_CTA]: 'View my bank transfer details',
  [MICROCOPY.ACCOUNT_PURCHASES_RESERVATION_DEPOSIT]: 'Reservation deposit',
  [MICROCOPY.ACCOUNT_PURCHASES_FINANCE_DEPOSIT]: 'Finance deposit',
  [MICROCOPY.ACCOUNT_PURCHASES_FINANCE]: 'Financier funds received',
  [MICROCOPY.ACCOUNT_PURCHASES_REMAINING_AMOUNT]: 'Payment received',
  [MICROCOPY.ACCOUNT_PURCHASES_TRADE_IN]: 'Trade-in payment received',
  [MICROCOPY.ACCOUNT_PURCHASES_FINANCE_PAYOUT]: 'Trade-in finance payout adjustment',
  [MICROCOPY.ACCOUNT_PURCHASES_DEPOSIT_PAID_ON]: '{amount} paid on {date}',
  [MICROCOPY.ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT]: accountPurchasesRemainingAmountPayment,
  [MICROCOPY.ACCOUNT_PURCHASES_REMAINING_FUNDS]: 'Remaining Funds',
  [MICROCOPY.ACCOUNT_PURCHASES_ORDER_COMPLETED]: 'Payment Completed',
  [MICROCOPY.ACCOUNT_PURCHASES_PAY_REMAINING_FUNDS]: 'Pay remaining funds',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DOCUMENTS]: accountPurchasesMyDocuments,
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DOCUMENTS_T_C]: 'Carma terms & conditions',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DOCUMENTS_PRIVACY_POLICY]: 'Carma privacy policy',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY]: 'Delivery',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION]: 'Collection',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_HEADER]: 'Delivery',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_NOT_SCHEDULED_CONTENT]:
    'When we have received all of the funds, we will organise and notify you regarding your delivery date & time. To change to collection, contact support and we can help you out.',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_SCHEDULED_CONTENT]:
    'You can view the date, time and status of your delivery here. Contact us to change the delivery date & time up to <strong>48 hours before</strong> your delivery time. To change to collection, contact support and we can help you out.',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_DELIVERED_CONTENT]: 'Your car has been delivered!',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_LOCATION_TITLE]: 'Your delivery location',
  [MICROCOPY.ACCOUNT_PURCHASES_DELIVERY_DATE_TIME_TITLE]: 'Your delivery date & time',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_HEADER]: 'Collection',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_NOT_SCHEDULED_CONTENT]:
    // eslint-disable-next-line no-template-curly-in-string
    'You can view the date, time and status of your collection window here. When we have received all of the funds, we will notify you <strong>{numberOfDays} days before</strong> your collection so you can plan your trip to Carma.',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_SCHEDULED_CONTENT]:
    'You can view the date, time and status of your collection here. Contact us to change the collection date & time up to <strong>48 hours before.</strong>',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_DELIVERED_CONTENT]: 'Your car has been collected!',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_LOCATION_TITLE]: 'The collection location',
  [MICROCOPY.ACCOUNT_PURCHASES_COLLECT_DATE_TIME_TITLE]: 'The collection window',
  [MICROCOPY.ACCOUNT_PURCHASES_GET_DIRECTIONS_BUTTON_LABEL]: 'Get directions',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_EXCLUDE_GOVERNMENT_CHARGES_LABEL]: 'Excl. Govt. Charges',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_TBD_BY_PAYMENT_LABEL]: 'TBD by payment method',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_PROCESSING_FEE_LABEL]: 'Processing fee',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_PAYMENT_METHOD_LABEL]: 'Payment method',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_BILLING_ADDRESS_LABEL]: 'Billing address',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_FUNDS_DUE_LABEL]: 'Funds due',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_TOTAL_LABEL]: 'Total',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_BACK_LABEL]: 'Back',
  [MICROCOPY.ACCOUNT_PURCHASES_FUNDS_REMAINING_PAY_BALANCE_LABEL]: 'Pay balance',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_SCHEDULED]:
    'You can view the date, time and status of your delivery here. Contact us to change the delivery date & time up to <b>48 hours before</b> your delivery time. To change to collection, contact support and we can help you out.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_NOT_SCHEDULED]:
    'When we have received all of the funds, we will organise and notify you regarding your delivery date & time. To change to collection, contact support and we can help you out.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_DELIVERED]: 'Your car has been delivered!',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_SCHEDULED]:
    'You can view the date, time and status of your collection here. Contact us to change the collection date & time up to <b>48 hours before</b>.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_NOT_SCHEDULED]:
    'You can view the date, time and status of your collection window here. Once we have received full payment and completed final checks, our team will contact you so you can plan your trip to collect the car from Carma.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION_IMAGE_DESCRIPTION_COLLECTED]: 'Your car has been collected!',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURN_REQUESTED]:
    'Vehicle return has been requested. The Carma team will give you a call to arrange the return.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURNED]: 'Vehicle has been returned.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_CANCELLED]: 'Delivery has been cancelled.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_TIME]: 'Your delivery date & time',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION_TIME]: 'Your collection date & time',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION]: 'Your delivery location',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_COLLECTION_LOCATION]: 'The collection location',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_TITLE]: 'Bank transfer details',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_DESCRIPTION]: 'Here are your unique Carma bank details.',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NAME]: '<strong>Account name:</strong> {accountName}',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_BSB]: '<strong>BSB:</strong> {bsb}',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NUMBER]:
    '<strong>Account number:</strong> {accountNumber}',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_REFERENCE_NUMBER]:
    '<strong>Reference number:</strong> {referenceNumber}',
  [MICROCOPY.ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_PAY_ID]: '<strong>Pay ID:</strong> {payId}',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_NOT_SCHEDULED_CONTENT]:
    'You can view the date, time and status of your delivery here. When we have received all of the funds, you can organise you delivery date & time or, the Carma team will give you a call to arrange the delivery of your car.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_ABLE_SCHEDULE_CONTENT]:
    'You can view the date, time and status of your delivery here. You can change your delivery date & time up to <b>48 hours before</b> your delivery time.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_UNABLE_SCHEDULE_CONTENT]:
    'You can view the date, time and status of your delivery here. You cannot change your date & time or location as your car with be delivered in less than 48 hours. If you urgently need to change your details please <a href={link}>contact us</a>.',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_CAR_DELIVERED_MESSAGE]: 'Your car has been delivered!',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION_BUTTON_LABEL]: 'Edit',
  [MICROCOPY.ACCOUNT_PURCHASES_MY_DELIVERY_TIME_BUTTON_LABEL]: 'Edit',
  [MICROCOPY.ACCOUNT_PERSONAL_DETAILS_TITLE]: 'Personal details',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_TITLE]: 'Personal information',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_NAME]: 'Name',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EMAIL]: 'Email',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_PHONE]: 'Phone',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_PASSWORD]: 'Password',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS]: 'Edit details',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_ADD_PHONE]: 'Add a mobile number',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_ADD_PASSWORD]: 'Create a password',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_ADD_PASSWORD_DESCRIPTION]:
    'To add a password to your account for the first time, you will need to create a password from an email we will send to you so we can verify your identity.',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE]: 'Change mobile',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE]: 'Mobile has been changed',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS]: `Your mobile has been successfully changed.`,
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_SUCCESS_TITLE]: 'Password has been changed',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_DESCRIPTION_SUCCESS]: `Your password has been successfully changed.`,

  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL]: 'New mobile number',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION]: 'e.g. 04XX XXX XXX',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL]: 'Save',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL]: 'Cancel',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL]: 'Close',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_TITLE]: 'Edit personal information',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_SUCCESS_TITLE]: 'Personal information has been changed',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_DESCRIPTION_SUCCESS]:
    'Your personal information has been successfully changed.',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_FIRST_NAME_LABEL]: 'First name',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_LAST_NAME_LABEL]: 'Last name',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_EMAIL_ADDRESS_LABEL]: 'Email address',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_SAVE_BUTTON_LABEL]: 'Save',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CANCEL_BUTTON_LABEL]: 'Cancel',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CLOSE_BUTTON_LABEL]: 'Close',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD]: 'Change password',
  [MICROCOPY.ACCOUNT_UPDATE_PERSONAL_INFO_SAVE_BUTTON_LABEL]: 'Save',
  [MICROCOPY.ACCOUNT_UPDATE_PERSONAL_INFO_CANCEL_BUTTON_LABEL]: 'Cancel',
  [MICROCOPY.ACCOUNT_UPDATE_PERSONAL_INFO_CLOSE_BUTTON_LABEL]: 'Close',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS]: 'Subscriptions',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS_OPTION]: 'I would like to receive Carma news and updates',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_TITLE]: 'Search alerts',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_BUTTON_LABEL]: 'Turn off all alerts',
  [MICROCOPY.ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_EMPTY]: 'Your search alerts will appear here.',
  [MICROCOPY.ACCOUNT_PAYMENT_TITLE]: 'Payment Method',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_TITLE]: 'Bank transfer',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_SUBTITLE]: 'No fees!',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_DESCRIPTION]:
    'Transfer the funds to us through your online banking website or app.<br>All the details you need are below.',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_INFO_TITLE]: 'Carma bank details',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_NAME]: 'Account name:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_BSB]: 'BSB:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT]: 'Account number:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_REF]: 'Reference number:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO]: accountPaymentOptionBankTransferImportantInfo,
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_TITLE]: 'We accept transfers from:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO]: accountPaymentOptionBankTransferImportantAcceptInfo,
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_CARD_TITLE]: 'Card payment',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_CARD_SUBTITLE]: 'Credit card fees apply ',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTION_CARD_SECURITY]: 'This is a secure SSL encrypted payment',
  [MICROCOPY.ACCOUNT_PAYMENT_ERROR_NO_PAYMENT_METHOD]: 'Please select a payment method',
  [MICROCOPY.ACCOUNT_PAYMENT_ERROR_CARD_INVALID]: 'There was a problem with your card payment. Need help? Contact us',
  [MICROCOPY.ACCOUNT_PAYMENT_CARD_INVALID_SHOW_CONTACT_TEXT]: 'here',
  [MICROCOPY.ACCOUNT_PAYMENT_FUNDS_REMAINING]: 'Funds remaining',
  [MICROCOPY.ACCOUNT_PAYMENT_PROCESSING_FEE]: 'Processing fee',
  [MICROCOPY.ACCOUNT_PAYMENT_TOTAL]: 'Total',
  [MICROCOPY.ACCOUNT_PAYMENT_PROCESSING_FEE_DEFAULT]: 'TBD by card',
  [MICROCOPY.ACCOUNT_PAYMENT_PROCESSING_FEE_FREE]: 'Free',
  [MICROCOPY.ACCOUNT_PAYMENT_NEXT_CARD]: 'Confirm and pay',
  [MICROCOPY.ACCOUNT_PAYMENT_NEXT_BANK_TRANSFER]: "I've paid",
  [MICROCOPY.ACCOUNT_PAYMENT_PREV]: 'Back',
  [MICROCOPY.ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_TITLE]: 'Confirm your transfer',
  [MICROCOPY.ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_OK]: "Yes, I've paid",
  [MICROCOPY.ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CANCEL]: "No, I haven't paid yet",
  [MICROCOPY.ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT]: accountPaymentBankTransferPopupContent,
  [MICROCOPY.ACCOUNT_PAYMENT_BANK_TRANSFER_COPY]: 'Copy',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_WATCHLIST_TITLE]: 'Watchlist alerts',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_DISABLE_ALL_BUTTON]: 'Turn off all alerts',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_PRICE_DROP]:
    'Notify me when there is a price drop on one of the cars in my watchlist.',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_VEHICLE_AVAILABLE]: 'Notify me when a car in my watchlist becomes available again.',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_PRICE_DROP_TITLE]: 'Watchlist price drop',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_PRICE_DROP_SUBTITLE]:
    'Notify me when there is a price drop on one of the cars in my watchlist.',
  [MICROCOPY.ACCOUNT_NOTIFICATIONS_WATCHLIST_WATCHERS_INCREASE]:
    'Notify me when a car in my watchlist is gaining popularity.',
  [MICROCOPY.MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_1_LABEL]: 'NRMA roadside assistance',
  [MICROCOPY.MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_2_LABEL]: 'Registration transfer guide',
  [MICROCOPY.MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_3_LABEL]: 'Carma Terms & Conditions',
  [MICROCOPY.MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_4_LABEL]: 'Carma Privacy Policy',
  [MICROCOPY.MY_ACCOUNT_PURCHASES_PAYMENT_RECEIVED]: myAccountPaymentReceived,
  [MICROCOPY.MY_ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT]: myAccountPaymentRemainingAmount,
  [MICROCOPY.MY_ACCOUNT_PURCHASES_RESERVATION_DEPOSIT]: myAccountPaymentReservationDeposit,
  [MICROCOPY.ACCOUNT_PAYID_HEADER]: 'How would you like to pay your remaining funds?',
  [MICROCOPY.ACCOUNT_PAYID_TITLE]: accountPayIdFormFirstOption,
  [MICROCOPY.ACCOUNT_PAYID_DEBIT_CREDIT_CARD_TITLE]: accountPayIdFormSecondOption,
  [MICROCOPY.ACCOUNT_PAYID_BANK_TRANSFER_TITLE]: accountPayIdFormThirdOption,
  [MICROCOPY.ACCOUNT_PAYID_BUTTON_LABEL]: 'Next',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAY_ID_HEADER]: 'Pay using PayID',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_DEBIT_CREDIT_CARD_HEADER]: 'Pay using debit or credit card',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_ONLINE_BANK_TRANSFER_HEADER]: 'Pay using online bank transfer',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_REMAINING_AMOUNT_TITLE]: 'Remaining amount',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PROCESS_FEE_TITLE]: 'Processing fee',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_TOTAL_TITLE]: 'Total',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_HEADER]: 'PayID details',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DETAILS_HEADER]: 'Card payment',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_HEADER]: 'Carma bank details',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DESCRIPTION]: 'This is secure SSL encrypted payment',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_COPY_TEXT]: 'Copy',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_LEFT_TEXT]: 'Your unique PayID email:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_1]: 'Account name:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_2]: 'BSB:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_3]: 'Account no.:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_4]: 'Reference no.:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO]: accountPaymentOptionsFormInfo,
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_INFO]: accountPaymentOptionsFormBankDetailsInfo,
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_BY]: 'PayID is accepted by:',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_DESCRIPTION]:
    'Plus over 60 other financial institutions',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_PAID_BUTTON_LABEL]: 'I’ve paid',
  [MICROCOPY.ACCOUNT_PAYMENT_OPTIONS_FORM_SUBMIT_BUTTON_LABEL]: 'Pay',
  [MICROCOPY.MY_ACCOUNT_PURCHASE_EMPTY_ACCOUNT]: 'You haven’t purchased any cars yet',
  //
  //
  // Authentication Workflow microcopy
  //
  //
  [MICROCOPY.AUTHENTICATION_SIGNUP_TITLE]: 'Log in or sign up',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CAPTION]: 'Start by entering your email address',
  [MICROCOPY.AUTHENTICATION_RESEND_PASSWORD_FORM_TITLE]: 'Check your email',
  [MICROCOPY.AUTHENTICATION_NEW_PASSWORD_FORM_TITLE]: 'Create a new password',
  [MICROCOPY.AUTHENTICATION_SIGNUP_WATCHLIST_TITLE]: 'Log in or sign up to add to your watchlist',
  [MICROCOPY.AUTHENTICATION_SIGNUP_WATCHLIST_CAPTION]: 'Start by entering your email address',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CAR_ALERTS_TITLE]: 'Log in or sign up for car alerts',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CAR_ALERTS_CAPTION]:
    'We’ll keep a lookout and let you know about cars that match your search',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CARMATCH_TITLE]: 'Log in or sign up',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CARMATCH_CAPTION]: 'Start by entering your email address',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CHECKOUT_TITLE]: 'Great choice!',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CHECKOUT_CAPTION]:
    'Log in or sign up and the car will be <b>reserved for 1 hour</b>',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CHECKOUT_SHORT_CAPTION]:
    'If you continue, we will place this car on hold for one hour.',
  [MICROCOPY.AUTHENTICATION_SIGNUP_LOGIN_FACEBOOK_LABEL]: 'Continue with Facebook',
  [MICROCOPY.AUTHENTICATION_SIGNUP_LOGIN_GOOGLE_LABEL]: 'Continue with Google',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CAR_ALERTS_DESCRIPTION]: `View our <a href='/privacy-policy'>privacy policy</a>, <a href='/collection-notice'>collection notice</a> and <a href='/terms'>terms</a> to understand how we use your personal information.`,
  [MICROCOPY.AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION]: authenticationResendPasswordFormCaption,
  [MICROCOPY.AUTHENTICATION_RESET_PASSWORD_FORM_TITLE]: 'Reset your password?',
  [MICROCOPY.AUTHENTICATION_RESET_PASSWORD_FORM_CAPTION]:
    'Don’t worry it happens to the best of us! Confirm your email address below to create a new password',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FORM_TITLE]: 'Welcome back, login to your account',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FORM_CAPTION]: 'Log in using __{email}__',
  [MICROCOPY.AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_TITLE]: 'Create your account',
  [MICROCOPY.AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_CAPTION]: 'Sign up with __{email}__',
  [MICROCOPY.AUTHENTICATION_VERIFICATION_FORM_TITLE]: 'Enter verification code',
  [MICROCOPY.AUTHENTICATION_VERIFICATION_FORM_CAPTION]:
    'We have sent a code to __{phone}__. This can take up to 2 minutes to receive',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_1]: "We'll reserve your car",
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_2]: 'We deliver within Greater Sydney (but we’re expanding!)',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_3]: 'Outside our delivery area?<br/>Pick up direct from Carma',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_1]: 'Create an account to start your watchlist!',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_2]: 'We’ll hold your car for 1 hour',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_3]: 'We deliver within Greater Sydney (but we’re expanding!)',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_1]: 'Be the first to know when new stock arrives',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_2]: 'Save time with a faster checkout',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_3]: 'Save your favourite cars and set up price alerts on them',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_1]: 'Free delivery to your driveway within Greater Sydney',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_2]: 'Flexible and transparent payments and car loans',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_3]: 'Create an account to start your watchlist!',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_1]: 'Reserve your car with a fully refundable $500 deposit',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_2]: 'Free delivery to your driveway within Greater Sydney',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_3]: 'Flexible and transparent payments and car loans',
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_CONTENT]: authenticationSignupSuccessContent,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_DEFAULT_FOOTER]: 'Redirecting you to where you were in {seconds}.',
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST_FOOTER]:
    'Your car has been saved to your watchlist. Redirecting you back in {seconds}.',
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_FOOTER_BUTTON]: 'Click here if you have not been redirected',
  [MICROCOPY.AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS]: authenticationSignupPrivacyAndTerm,
  [MICROCOPY.AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_TITLE]: 'Sign up or login to create your personalised quote',
  [MICROCOPY.AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_CAPTION]: 'Start by entering your email address',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_1]: "Browse our site to find the car you'd like to buy",
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_2]: 'Get your personalised quote in minutes',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_3]: 'Finance your dream car',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_1]: 'Free delivery to your driveway within Greater Sydney',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_2]: 'Flexible and transparent payments and car loans',
  [MICROCOPY.AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_3]: 'Create an account to start your watchlist!',
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT]: authenticationSignupSuccessCheckout,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR]: authenticationSignupSuccessNavbar,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST]: authenticationSignupSuccessWatchList,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS]: authenticationSignupSuccessCarAlerts,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE]: authenticationSignupSuccessPersonalisedQuote,
  [MICROCOPY.AUTHENTICATION_SIGNUP_SUCCESS_BUTTON]: 'Click here if you have not been redirected',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_TITLE]: 'Confirm your details',
  [MICROCOPY.AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_SUBTITLE]: 'Sign up with **{email}**',
  //
  //
  // Verify phone number microcopy
  //
  //
  [MICROCOPY.VERIFY_PHONE_NUMBER_FORM_PHONE_TITLE]: "Now let's verify your mobile",
  [MICROCOPY.VERIFY_PHONE_NUMBER_FORM_PHONE_SUBTITLE]:
    'Add your mobile so we can make your experience as secure as possible.',
  [MICROCOPY.VERIFY_PHONE_NUMBER_FORM_VERIFY_TITLE]: 'Enter verification code',
  [MICROCOPY.VERIFY_PHONE_NUMBER_FORM_VERIFY_PHONE_SUBTITLE]: 'We have sent a verification code to',
  //
  //
  // Set location microcopy
  //
  //
  [MICROCOPY.DELIVERY_LOCATION_POPUP_TITLE_START]: 'Enter a postcode or suburb',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_ENTER_LOCATION_FIELD]: 'Postcode or Suburb name',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_ENTER_LOCATION_HELPER]: 'e.g. 2000 or Sydney',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_ENTER_LOCATION_ERROR]: 'Enter a postcode or suburb, e.g. 2000 or Sydney',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_TITLE_DELIVERY_COLLECTION]: 'Delivery or collection',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_TITLE]: 'Delivery available',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_DESCRIPTION]: 'Great! We can deliver to you.',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_EDIT_LOCATION]: 'Location:',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_TITLE]: 'Unfortunately we can’t deliver to your area',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_DESCRIPTION]:
    "Add your email and we'll notify you when delivery is available.",
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_FIELD]: 'Your email address',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_ERROR]: 'Email address does not exist or invalid',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_BUTTON]: 'Save',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_SUCCESS]:
    'Thank you, we’ll reach out as soon as delivery is available for you!',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_COLLECTION_TITLE]: 'Your nearest collection location',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_COLLECTION_DESCRIPTION]:
    'Interested in pickup? After purchase, you’ll be contacted by Carma to organise a pickup time that best suits you.',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_FOOTER]: 'You’ll be able specify your final delivery choice during checkout.',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_CONFIRM]: 'Confirm',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_OUT_OF_AREA_SUBTITLE]: 'Looks like you’re outside our service area',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_OUT_OF_AREA_DESCRIPTION]:
    'Sorry, delivery to your location is not available at this time. As an interstate customer, you may still be able to purchase the car and collect it from one of our collection points in NSW.',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_OUT_OF_AREA_CALL_US_DESCRIPTION]:
    'Add your email and we’ll notify you when delivery is available.',
  [MICROCOPY.DELIVERY_LOCATION_POPUP_PICKUP_LOCATION]: 'Alexandria 2015 NSW',
  [MICROCOPY.DELIVERY_LOCATION_DIRECTIONS_BUTTON]: 'Get Directions',
  //
  //
  // Personalized quote microcopy
  //
  //
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_HEADER]: 'Loan details',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_DESCRIPTION]: 'Why do we need this?',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_CTA]: 'Next: Type of loan',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_TITLE]: 'Why do we need your build year?',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_DESCRIPTION]:
    'Lenders tend to give better rates for newer vehicles. Remember you can always change this later and see the adjusted quote',
  [MICROCOPY.PERSONALIZED_QUOTE_EMAIL_FORM_HEADER]: 'Enter your email',
  [MICROCOPY.PERSONALIZED_QUOTE_EMAIL_FORM_CTA]: 'Next: Personal details',
  [MICROCOPY.PERSONALIZED_QUOTE_EMAIL_FORM_DESCRIPTION]: `View our <a href='/privacy-policy'>Privacy Collection Notice</a> to understand how we use your personal information.`,
  [MICROCOPY.PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_HEADER]: 'Enter your phone number',
  [MICROCOPY.PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_CTA]: 'Next: Personal details',
  [MICROCOPY.PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_DESCRIPTION]: `View our <a href='/privacy-policy'>Privacy Collection Notice</a> to understand how we use your personal information.`,
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_TYPE_FORM_HEADER]: 'What type of loan do you want?',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA]: 'Next: Email address',
  [MICROCOPY.PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA_LOGGED_IN]: 'Next: Personal details',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGN_IN_FORM_FORGOT_PASSWORD_CTA]: 'Forgot password?',
  [MICROCOPY.PERSONALIZED_QUOTE_FORGOT_PASSWORD_DESCRIPTION]:
    'Don’t worry it happens to the best of us! Confirm your email address below to create a new password.',
  [MICROCOPY.PERSONALIZED_QUOTE_FORGOT_PASSWORD_CTA]: 'Send me a reset link',
  [MICROCOPY.PERSONALIZED_QUOTE_RESEND_PASSWORD_CTA]: 'Resend link',
  [MICROCOPY.PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_PERSONAL_HEADER]: 'Tell us about yourself',
  [MICROCOPY.PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_BUSINESS_HEADER]: 'Tell us about your business and yourself',
  [MICROCOPY.PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_CTA]: 'Next: Residential address',
  [MICROCOPY.PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FORM_HEADER]: 'Home & family',
  [MICROCOPY.PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FAMILY_STATUS_TITLE]: 'Family status',
  [MICROCOPY.PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_RESIDENTIAL_ADDRESS_TITLE]: 'Residential address',
  [MICROCOPY.PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_PERSONAL_BUTTON_SUBMIT]: 'Next: Employment & Citizenship',
  [MICROCOPY.PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_BUSINESS_BUTTON_SUBMIT]: 'Next: Australian residency status',
  [MICROCOPY.PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_HEADER]: 'Employment & Citizenship',
  [MICROCOPY.PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_EMPLOYMENT_STATUS_TITLE]: 'Employment status',
  [MICROCOPY.PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_RESIDENCY_STATUS_TITLE]: 'Australian residency status',
  [MICROCOPY.PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_SUBMIT_BUTTON_LABEL]: 'Next: Terms & conditions',
  [MICROCOPY.PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_HEADER]: 'Term & conditions',
  [MICROCOPY.PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA]: 'Next: quote options',
  [MICROCOPY.PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA_NEW_USER]: 'Next: Secure your account',
  [MICROCOPY.PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CHECKBOX]:
    'I have read, understood and agree to the terms and conditions above',
  [MICROCOPY.PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_DESCRIPTION]: 'Don’t worry, this won’t impact your credit score',
  [MICROCOPY.PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION]: personalizedQuoteConfirmationPageContent,
  [MICROCOPY.PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION_NO_QUOTES]: personalizedQuoteConfirmationPageContentNoQuotes,
  [MICROCOPY.PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_BUTTON_LABEL]: 'Save {quotes} to your account',
  [MICROCOPY.PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_NEXT_PAGE_BUTTON_LABEL]: 'Next: Confirmation',
  [MICROCOPY.PERSONALIZED_QUOTE_QUOTES_LIST_TITLE]: 'Your personalised quotes',
  [MICROCOPY.PERSONALIZED_QUOTE_QUOTES_LIST_DESCRIPTION]:
    'You can adjust your loan term and payment frequency then choose to save any of the quotes to your account.',
  [MICROCOPY.PERSONALIZED_QUOTE_CONFIRMATION_BUTTON_LABEL]: 'Go to my account',
  [MICROCOPY.PERSONALIZED_QUOTE_RESULT_CARD_TITLE]:
    'We’ve found {carsFound} Carma cars that match your budget and preferences.',
  [MICROCOPY.PERSONALIZED_QUOTE_RESULT_CARD_DESCRIPTION]:
    'We add new cars every day, so when you’re ready to purchase you’ll be spoilt for choice.',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGNUP_HEADER]: 'View your quotes within a secure account',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGNUP_HEADER_DESCRIPTION]:
    'Create a password and verify your mobile to view your personalised quotes.',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGNUP_CTA]: 'Secure account',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGNUP_CHECKBOX]: 'I want to receive Carma news and updates',
  [MICROCOPY.PERSONALIZED_QUOTE_SIGNUP_TERM_AND_POLICY]: `By selecting Create account, I agree to Carma’s :a[Terms]{href="/ts&cs" target="_blank"} and :a[Privacy Policy]{href="/privacy-policy" target="_blank"} which includes our Collection statement`,
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_DESCRIPTION]:
    'Lenders often change their interest rates meaning the quotes you receive will no longer hold true. We will remove the quotes when this happens so be sure to act quickly...',
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_HEADER_BUTTON_LABEL]: 'Create a new quote',
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_1_CONTENT]: 'Open and transparent  interest rates and fees.',
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_2_CONTENT]:
    '100% digital process with no paper forms to fill out.',
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_3_CONTENT]: 'Approvals typically by the same business day.',
  [MICROCOPY.PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_INFORMATION]:
    '"Important Information": The indicative rate is an annualised interest rate that reflects the interest rate we generally provide to applicants based on the information provided and the applicant’s credit rating. The actual loan interest rate will be determined based on our full assessment of the application and the information you provide. The actual interest rate, if the loan is approved, may vary from the indicative rate. <br /> <br /> *Fees and charges apply. Repayments are estimates only, based on a personalised interest rate supplied by the financier based on your inputs. Final rate and all applications subject to financier assessment and approval.”',
  [MICROCOPY.PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA]: 'Start your finance journey',
  [MICROCOPY.PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA_LABEL]: 'Chat to our finance team',
  [MICROCOPY.PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_TITLE]: 'Looks like you haven’t created any personalised quotes yet.',
  [MICROCOPY.PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_DESCRIPTION]:
    'Working with trusted, carefully selected lenders, we take the hassle out of negotiating a good deal with flexible finance options.',
  [MICROCOPY.PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_CTA]: 'Get a personalised quote',
  //
  //
  // Content hub microcopy
  //
  //
  [MICROCOPY.CONTENT_HUB_TITLE]: 'Carma content hub',
  [MICROCOPY.CONTENT_HUB_NEWSLETTER_TITLE]: 'Get the best car news delivered straight to your inbox',
  [MICROCOPY.CONTENT_HUB_NEWSLETTER_SUBMIT]: 'Become a member',
  [MICROCOPY.CONTENT_HUB_NEWSLETTER_SUCCESS]: 'Thanks for becoming a member',
  [MICROCOPY.CONTENT_HUB_RELATED_ARTICLES_TITLE]: 'View some related articles',
  //
  //
  // CarMatch microcopy
  //
  //
  [MICROCOPY.CARMATCH_START_PAGE]: carMatchStartPage,
  [MICROCOPY.CARMATCH_START_PAGE_CTA]: 'Get started',
  [MICROCOPY.CARMATCH_RESULTS_HEADER_MULTIPLE_RESULTS]: 'Here are {totalModels} models we think you will love.',
  [MICROCOPY.CARMATCH_RESULTS_HEADER_SINGLE_RESULT]: 'Here is a model we think you will love.',
  [MICROCOPY.CARMATCH_RESULTS_VIEW_ALL_TITLE]: 'Want to see all of your matches?',
  [MICROCOPY.CARMATCH_RESULTS_VIEW_ALL_DESCRIPTION]: 'View all cars that match your requirements.',
  [MICROCOPY.CARMATCH_RESULTS_VIEW_ALL_CTA]: 'View all {totalCars}',
  [MICROCOPY.CARMATCH_RESULTS_SEE_AVAILABLE_CTA]: 'See {numberOfMakes} results',
  [MICROCOPY.CARMATCH_RESULTS_CAROUSEL_TILE_PRICE_DESCRIPTION]: 'Starting from',
};

export default microcopyDefaultDictionary;
