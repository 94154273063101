import { produce } from 'immer';
import { useLayoutEffect } from 'react';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

import {
  PageProductListingFilters,
  PageProductListingOptions,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { FilterListPayload } from 'src/types/CarFilters.types';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';

/**
 * Store to manage PLP page
 * Follows Zustand store implementation with Next.JS example here:
 * https://github.com/vercel/next.js/tree/canary/examples/with-zustand
 */

interface PlpStoreStateData {
  /** Filters currently set for the PLP page */
  filters: PageProductListingFilters;
  /** Listing option set for the PLP page */
  listingOptions: PageProductListingOptions;
  /** List of all the filters data */
  filterData: FilterListPayload;
  /**
   * Map structure of all the PLP pages associated with a filter.
   * Not set directly as a Map object directly as it can't be serialised for SSR
   */
  plpPagesMap: [string, string][];

  isLoadingProducts: boolean;
  /** List of all products with current filter */
  products: ProductListDataPayload;
}

export interface PlpStoreState extends PlpStoreStateData {
  set: (fn: (state: PlpStoreState) => void) => void;
  resetFilters: () => void;
  setFilters: (filters: PageProductListingFilters, listingOptions?: PageProductListingOptions) => void;
  setListingOptions: (listingOptions: PageProductListingOptions) => void;
}

let store: StoreApi<PlpStoreState> | undefined;
const plpStoreContext = createContext<StoreApi<PlpStoreState>>();

const getDefaultInitialState: () => PlpStoreStateData = () => ({
  filters: {},
  listingOptions: {},
  plpPagesMap: [],
  filterData: {
    makeAndModel: [],
    seats: [],
    doors: [],
    transmission: [],
    fuelType: [],
    bodyType: [],
    colour: [],
    ancapSafetyRating: [],
    highlightedFeatures: [],
    year: [],
    kilometers: [],
    price: { cashList: [], financeList: [] },
    driveType: [],
    co2Emissions: [],
    promotionTags: '',
  },
  isLoadingProducts: true,
  products: {
    total: 0,
    results: [],
  },
});

const initialisePlpStoreLegacy = (preloadedState: Partial<PlpStoreStateData> = {}) =>
  create<PlpStoreState>((set, _get) => ({
    ...getDefaultInitialState(),
    ...preloadedState,
    set: (fn) => set(produce(fn)),
    resetFilters: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.filters = {};
        }),
      ),
    setFilters: (params: PageProductListingFilters, listingOptions?: PageProductListingOptions) => {
      set((state) =>
        produce(state, (draft) => {
          draft.filters = params;
          if (listingOptions !== undefined) {
            draft.listingOptions = listingOptions;
          }
        }),
      );
    },
    setListingOptions: (params: PageProductListingOptions) => {
      set((state) =>
        produce(state, (draft) => {
          draft.listingOptions = params;
        }),
      );
    },
  }));

export const PlpStoreLegacyProvider = plpStoreContext.Provider;

export const plpStoreLegacy = plpStoreContext.useStore;

export const useCreatePlpStoreLegacy = (serverInitialState: Partial<PlpStoreStateData>) => {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initialisePlpStoreLegacy(serverInitialState);
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store);
  store = store ?? initialisePlpStoreLegacy(serverInitialState);
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      store!.setState(
        {
          // re-use functions from existing store
          ...store!.getState(),
          // but reset all other properties.
          ...serverInitialState,
        },
        true, // replace states, rather than shallow merging
      );
    }
  });

  return () => store!;
};
