import color from './color.export.module.scss';

const baseColor = {
  border: {
    0: color['border-0'],
    1: color['border-1'],
    2: color['border-2'],
    3: color['border-3'],
  },
  neutral: {
    0: color['neutral-0'],
    30: color['neutral-30'],
    50: color['neutral-50'],
    100: color['neutral-100'],
    200: color['neutral-200'],
    300: color['neutral-300'],
    400: color['neutral-400'],
    500: color['neutral-500'],
    600: color['neutral-600'],
    700: color['neutral-700'],
    800: color['neutral-800'],
    900: color['neutral-900'],
  },
  'ui-primary': {
    0: color['uiPrimary-0'],
    25: color['uiPrimary-25'],
    50: color['uiPrimary-50'],
    100: color['uiPrimary-100'],
    200: color['uiPrimary-200'],
    300: color['uiPrimary-300'],
    400: color['uiPrimary-400'],
    500: color['uiPrimary-500'],
    600: color['uiPrimary-600'],
    700: color['uiPrimary-700'],
    800: color['uiPrimary-800'],
    900: color['uiPrimary-900'],
  },
  'brand-primary': {
    50: color['brandPrimary-50'],
    100: color['brandPrimary-100'],
    300: color['brandPrimary-300'],
  },
  sentimental: {
    info: {
      50: color['sentimental-info-50'],
      100: color['sentimental-info-100'],
      200: color['sentimental-info-200'],
      600: color['sentimental-info-600'],
      700: color['sentimental-info-700'],
      800: color['sentimental-info-800'],
    },
    success: {
      50: color['sentimental-success-50'],
      100: color['sentimental-success-100'],
      200: color['sentimental-success-200'],
      600: color['sentimental-success-600'],
      700: color['sentimental-success-700'],
      800: color['sentimental-success-800'],
    },
  },
  opacity: {
    dark: {
      'high-emphasis': '#0b051d',
      'medium-emphasis': '#0b051dcc',
      'low-emphasis': '#0b051d8c',
    },
    light: {
      'high-emphasis': 'rgba(255, 255, 255, 0.92)',
      'medium-emphasis': 'rgba(255, 255, 255, 0.65)',
      'low-emphasis': 'rgba(255, 255, 255, 0.43)',
      'very-low-emphasis': 'rgba(255, 255, 255, 0.3)',
    },
  },
};

const colorMap = {
  ...baseColor,

  action: {
    background: {
      button: {
        primary: {
          default: baseColor['ui-primary'][600],
          hover: baseColor['ui-primary'][700],
          'on-press': baseColor['ui-primary'][800],
          disabled: '#6F6E89',
        },
        secondary: {
          default: baseColor.neutral[0],
          hover: baseColor.neutral[50],
          'on-press': baseColor.neutral[100],
          disabled: baseColor.neutral[300],
        },
        tertiary: {
          default: baseColor.opacity.light['low-emphasis'],
          hover: baseColor.opacity.light['medium-emphasis'],
          'on-press': baseColor.opacity.light['high-emphasis'],
          disabled: baseColor.opacity.light['low-emphasis'],
        },
        tab: {
          default: baseColor.neutral[0],
          hover: baseColor.neutral[50],
          'on-press': baseColor.neutral[100],
          active: baseColor['ui-primary'][600],
        },
      },
      menu: {
        default: baseColor.neutral[0],
        hover: baseColor.neutral[100],
        'on-press': baseColor['ui-primary'][600],
        disabled: baseColor.neutral[0],
      },
      tooltip: baseColor.opacity.dark['high-emphasis'],
      'call-out': {
        default: baseColor.neutral[50],
        hover: baseColor.neutral[100],
        'on-press': baseColor.neutral[200],
        disabled: baseColor.neutral[300],
      },
      'selection-button': {
        default: baseColor.neutral[0],
        hover: baseColor['ui-primary'][25],
        'on-press': baseColor['ui-primary'][50],
        'active-default': baseColor['ui-primary'][25],
        'active-hover': baseColor['ui-primary'][50],
        'active-on-press': baseColor['ui-primary'][100],
        disabled: baseColor.neutral[300],
        error: baseColor.neutral[0],
      },
      'selection-card': {
        default: baseColor.neutral[0],
        hover: baseColor['ui-primary'][25],
        'on-press': baseColor['ui-primary'][50],
        'active-default': baseColor['ui-primary'][25],
        'active-hover': baseColor['ui-primary'][50],
        'active-on-press': baseColor['ui-primary'][100],
        disabled: baseColor.neutral[300],
        error: baseColor.neutral[0],
      },
      'text-input': {
        default: baseColor.neutral[0],
        hover: baseColor.neutral[0],
        active: baseColor.neutral[0],
        error: baseColor.neutral[0],
        disabled: baseColor.neutral[300],
      },
      accordion: {
        default: baseColor.neutral[0],
      },
    },
    text: {
      button: {
        primary: {
          default: baseColor.opacity.light['high-emphasis'],
          hover: baseColor.opacity.light['high-emphasis'],
          'on-press': baseColor.opacity.light['high-emphasis'],
          disabled: baseColor.opacity.light['low-emphasis'],
        },
        secondary: {
          default: baseColor.opacity.dark['high-emphasis'],
          hover: baseColor.opacity.dark['high-emphasis'],
          'on-press': baseColor.opacity.dark['high-emphasis'],
          disabled: baseColor.opacity.dark['low-emphasis'],
        },
        tertiary: {
          default: baseColor.opacity.dark['high-emphasis'],
          hover: baseColor.opacity.dark['high-emphasis'],
          'on-press': baseColor.opacity.dark['high-emphasis'],
          disabled: baseColor.opacity.dark['low-emphasis'],
        },
      },
      selection: {
        primary: {
          default: baseColor['ui-primary'][600],
          hover: baseColor['ui-primary'][700],
          'on-press': baseColor['ui-primary'][800],
          disabled: baseColor.opacity.dark['low-emphasis'],
          active: baseColor.neutral[0],
        },
      },
      input: {
        label: {
          default: baseColor.opacity.dark['medium-emphasis'],
          disabled: baseColor.opacity.dark['low-emphasis'],
        },
        value: {
          default: baseColor.opacity.dark['high-emphasis'],
          disabled: baseColor.opacity.dark['low-emphasis'],
        },
        prefix: baseColor.opacity.dark['low-emphasis'],
        suffix: baseColor.opacity.dark['low-emphasis'],
        helper: baseColor.opacity.dark['low-emphasis'],
        assistive: {
          default: baseColor.opacity.dark['low-emphasis'],
          success: baseColor.sentimental.success[600],
        },
      },
      link: {
        default: baseColor['ui-primary'][600],
        hover: baseColor['ui-primary'][700],
        'on-press': baseColor['ui-primary'][800],
      },
    },
    border: {
      button: {
        secondary: {
          default: baseColor.neutral[100],
          hover: baseColor.neutral[200],
          'on-press': baseColor.neutral[300],
          disabled: baseColor.neutral[300],
        },
      },
      'text-input': {
        default: baseColor.neutral[100],
        hover: baseColor['ui-primary'][700],
        active: baseColor['ui-primary'][800],
        disabled: baseColor.neutral[300],
      },
      'selection-button': {
        default: baseColor.neutral[100],
        hover: baseColor['ui-primary'][300],
        'on-press': baseColor['ui-primary'][400],
        'active-default': baseColor['ui-primary'][600],
        'active-hover': baseColor['ui-primary'][700],
        'active-on-press': baseColor['ui-primary'][800],
        disabled: baseColor.neutral[300],
      },
      'selection-card': {
        default: baseColor.neutral[0],
        hover: baseColor['ui-primary'][300],
        'on-press': baseColor['ui-primary'][400],
        'active-default': baseColor['ui-primary'][600],
        'active-hover': baseColor['ui-primary'][700],
        'active-on-press': baseColor['ui-primary'][800],
        disabled: baseColor.neutral[300],
      },
    },
  },
};

export default colorMap;
