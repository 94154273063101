/* eslint-disable function-paren-newline */

/* eslint-disable implicit-arrow-linebreak */

import { BodyType, FiltersPayloadRow, FormFilterValues, PlpFilterParams } from '../types/CarFilters.types';
import { FilterValues } from '../types/CataloguePage.types';

interface RangeFilterFormValues {
  min: string;
  max: string;
}

interface DropdownFormFilterValues {
  value: 'string' | CheckBoxOption.all;
}

type CheckboxFormFilterValues = FormFilterValues<boolean>;

const enum CheckBoxOption {
  all = 'all',
}

const enum FilterType {
  makeAndModel = 'makeAndModel',
  keyword = 'keyword',
  price = 'price',
  year = 'year',
  kilometers = 'kilometers',
  enginePower = 'enginePower',
  fuelConsumption = 'fuelConsumption',
  transmission = 'transmission',
  highlightedFeatures = 'highlightedFeatures',
  seats = 'seats',
  doors = 'doors',
  ancapSafetyRating = 'ancapSafetyRating',
  co2Emissions = 'co2Emissions',
  colour = 'colour',
  bodyType = 'bodyType',
  bodySize = 'bodySize',
  fuelType = 'fuelType',
  driveType = 'driveType',
  state = 'state',
  promotionTags = 'promotionTags',
}

function genSelectQuery(data: CheckboxFormFilterValues = {}, filterKey: FilterValues): FiltersPayloadRow[] {
  return Object.keys(data)
    .filter((key) => key !== 'all')
    .map((key: string) => ({ filter: filterKey, value: key }));
}

// TODO typings
function getMakeModelsFilterPayload(data: any[]): FiltersPayloadRow[] {
  const result: FiltersPayloadRow[] = [];

  if (data) {
    data.forEach((make) => {
      // If 'all' is selected for a models then we skip adding models and simply add the make
      if (make.models && make.models.some((model: { key: string }) => model.key === 'all')) {
        result.push({
          filter: FilterValues.Make,
          value: make.key,
        });
      } else if (make.models && Array.isArray(make.models)) {
        make.models.forEach((model: { key: any }) => {
          result.push({
            filter: FilterValues.Model,
            value: model.key,
          });
        });
      }
    });
  }

  return result;
}

function getBodyTypeAndSizeFilterPayload(data: BodyType[]): FiltersPayloadRow[] {
  const result: FiltersPayloadRow[] = [];

  if (!data) {
    return result;
  }

  data.forEach((bodyType) => {
    if (!bodyType.sizes) {
      return;
    }
    if (bodyType.sizes.some((bodySize: { key: string }) => bodySize.key === 'all')) {
      // If 'all' is selected for a body size then we skip adding sizes and simply add the body type
      result.push({
        filter: FilterValues.BodyType,
        value: bodyType.key,
      });
    } else if (Array.isArray(bodyType.sizes)) {
      bodyType.sizes.forEach((bodySize: { key: any }) => {
        result.push({
          filter: FilterValues.BodySize,
          value: bodySize.key,
        });
      });
    }
  });

  return result;
}

const getDropdownPayload = (data: DropdownFormFilterValues, filterType: FilterValues): FiltersPayloadRow[] =>
  data && data.value !== CheckBoxOption.all ? [{ filter: filterType, value: data.value }] : [];

const getRangeDropdownPayload = (
  data: RangeFilterFormValues,
  filterTypeMin: FilterValues,
  filterTypeMax: FilterValues,
): FiltersPayloadRow[] => {
  const result: FiltersPayloadRow[] = [];
  if (!data) {
    return result;
  }
  const { min, max } = data;
  // Don't push to filters payload if 'all' is not selected
  if (min && min !== CheckBoxOption.all) {
    result.push({
      filter: filterTypeMin,
      value: min,
    });
  }
  if (max && max !== CheckBoxOption.all) {
    result.push({
      filter: filterTypeMax,
      value: max,
    });
  }
  return result;
};

export const getStatePayload = (data?: string): FiltersPayloadRow[] => {
  if (!data) {
    return [];
  }

  if (data === 'available') {
    return [
      { filter: FilterValues.State, value: 'available' },
      { filter: FilterValues.State, value: 'draftOrder' },
    ];
  }

  return [{ filter: FilterValues.State, value: data }];
};

// TODO the frontend filter data structure is dimorphic to the backend structure, need to eventually align both for ease of development
/**
 * Creates a filter payload that conforms to the backend requirements.
 * @param data
 * @param key
 */
function getFilterParam(
  data: CheckboxFormFilterValues | RangeFilterFormValues | DropdownFormFilterValues | any, // the 'any' here is to take into account price, and make & model (for now)
  key: string,
): FiltersPayloadRow[] {
  switch (key) {
    case FilterType.makeAndModel:
      return getMakeModelsFilterPayload(data);

    case FilterType.price:
      return data.priceType === 'cash'
        ? getRangeDropdownPayload(data, FilterValues.CashMin, FilterValues.CashMax)
        : getRangeDropdownPayload(data, FilterValues.FinanceMin, FilterValues.FinanceMax);

    case FilterType.year:
      return getRangeDropdownPayload(data, FilterValues.YearMin, FilterValues.YearMax);

    case FilterType.kilometers:
      return getRangeDropdownPayload(data, FilterValues.KMMin, FilterValues.KMMax);

    case FilterType.enginePower:
      return getRangeDropdownPayload(data, FilterValues.EnginePowerMin, FilterValues.EnginePowerMax);

    case FilterType.fuelConsumption:
      return getRangeDropdownPayload(data, FilterValues.FuelConsumptionMin, FilterValues.FuelConsumptionMax);

    case FilterType.transmission:
      return genSelectQuery(data, FilterValues.Transmission);

    case FilterType.fuelType:
      return genSelectQuery(data, FilterValues.FuelType);

    case FilterType.bodyType:
      return getBodyTypeAndSizeFilterPayload(data);

    case FilterType.driveType:
      return genSelectQuery(data, FilterValues.DriveType);

    case FilterType.highlightedFeatures:
      return genSelectQuery(data, FilterValues.HighlightedFeatures);

    case FilterType.seats:
      return genSelectQuery(data, FilterValues.Seats);

    case FilterType.doors:
      return genSelectQuery(data, FilterValues.Doors);

    case FilterType.colour:
      return genSelectQuery(data, FilterValues.Colour);

    case FilterType.co2Emissions:
      return getDropdownPayload(data, FilterValues.Co2emissions);

    case FilterType.ancapSafetyRating:
      return getDropdownPayload(data, FilterValues.Ancap);

    case FilterType.keyword:
      return data.search ? [{ filter: FilterValues.Keyword, value: data.search }] : [];

    case FilterType.state:
      return getStatePayload(data);

    case FilterType.promotionTags:
      return data ? [{ filter: FilterValues.promotionTags, value: data }] : [];

    default:
      return [];
  }
}

export function parseFilterParams(params: PlpFilterParams): FiltersPayloadRow[] {
  if (!params) {
    return [];
  }

  let filters: FiltersPayloadRow[] = [];

  Object.keys(params).forEach((key: string) => {
    const param = getFilterParam(params[key as FilterType], key);
    if (param) {
      if (Array.isArray(param)) {
        filters = [...filters, ...param];
      } else {
        filters.push(param);
      }
    }
  });
  return filters;
}

export function isNumber(n: any) {
  return `${+n}` === `${n}`;
}

export const parseCategory = (filterData: any[], make: any, model: any) => {
  const makeFound = filterData?.find((e: any) => e.name === make.toLowerCase());

  if (!makeFound) {
    return [];
  }

  const allModel = !model && {
    key: 'all',
    name: 'all',
    parentKey: makeFound.key,
    label: 'All',
    quantity: makeFound.quantity,
  };

  const modelFound = makeFound.models.find((m: any) => m.name === model?.toLowerCase()) || allModel;

  return [{ ...makeFound, models: [modelFound] }];
};

export const parseBodyTypesAndSizes = (filterData: any[], bodyType: string, bodySize: string) => {
  // If body type label matches possible body type filter labels
  const bodyTypeFound = filterData?.find((e: any) => e.label.toUpperCase() === bodyType.toUpperCase());

  if (!bodyTypeFound) {
    return [];
  }

  // If a body type is selected with no body size
  const allSizes = !bodySize && {
    key: 'all',
    label: 'All',
    quantity: bodyTypeFound.quantity,
  };

  const sizesFound =
    bodyTypeFound.sizes.find((m: any) => m.label.toUpperCase() === bodySize?.toUpperCase()) || allSizes;
  return [{ ...bodyTypeFound, sizes: [sizesFound] }];
};
